export default {
  last_page: state => state.pagination.last_page,
  items_count: state => state.pagination.items_count,
  itemsByPage: state => state.items,
  itemById: state => id => {
    for (let key in state.items) {
      if (state.items.hasOwnProperty(key) && Number(state.items[key].id) === Number(id)) {
        return state.items[key]
      }
    }
    return null
  },
}
