<template>
	<div>
		<div class="border-radius-lg border mb-20px">
			<div class="p-0 bg-white border-0 d-flex align-items-center justify-content-between p-20px cursor-pointer" @click="visible = !visible">
				<div class="fs-20px fw-600" v-if="bid.program">Программа "{{bid.program.name}}"</div>
				<IconChevronDown :style="`transition: .2s ease all; transform: rotate(${visible ? '0deg' : '180deg'})`" />
			</div>
			<b-collapse :id="`accordion_${bid.id}`" v-model="visible" :accordion="`my-accordion_${bid.id}`">
				<div class="p-0 bg-white border-0  px-20px pb-20px">

					<ValidationObserver tag="form"
										@submit.prevent="submitForm"
										ref="observer-form"
										v-slot="{ invalid }"
										method="POST">

						<ValidationProvider tag="div" rules="required" name="Cогласие на прохождение выбранной программы" v-slot="{ errors }">
							<div :class="'d-inline-flex px-12px py-8px border-radius-sm bg-gray-10 position-relative mb-20px form-check' + (errors.length ? ' is-invalid' : '')">
								<input type="checkbox"
									   v-model="form.consent"
									   :name="`form-field-consent_${bid.id}`"
									   :id="`form-field-consent_${bid.id}`"
									   class="form-check-input ms-0 form-check-input-bordered"
									   v-if="!bid.give_consent" />
								<span :disabled="true"
									  :class="`form-check-input checked ms-0 form-check-input-bordered`"
									  v-else />
								<label :for="`form-field-consent_${bid.id}`"
									   class="form-label ms-12px mb-0">Даю свое согласие на прохождение выбранной программы</label>
							</div>
							<div v-if="errors.length" class="invalid-tooltip">
								<span v-for="err in errors" :key="`form-field-agreement_err`">{{err}}</span>
							</div>
						</ValidationProvider>

						<div class="row mb-30px" v-if="currentConfiguration">
							<div class="col-12 col-sm-4 d-flex flex-column gap-3">
								<dl class="d-flex gap-2 mb-0" v-if="currentConfiguration.hours_volume">
									<dt class="text-muted w-150px">Объем часов</dt>
									<dd class="mb-0">{{currentConfiguration.hours_volume}}</dd>
								</dl>
								<dl class="d-flex gap-2 mb-0" v-if="currentConfiguration.study_form">
									<dt class="text-muted w-150px">Форма обучения</dt>
									<dd class="mb-0">{{currentConfiguration.study_form.name}}</dd>
								</dl>
								<dl class="d-flex gap-2 mb-0" v-if="bid.program.type_educational_program">
									<dt class="text-muted w-150px">Вид программы</dt>
									<dd class="mb-0">{{bid.program.type_educational_program.name}}</dd>
								</dl>
								<dl class="d-flex gap-2 mb-0" v-if="currentConfiguration.price">
									<dt class="text-muted w-150px">Стоимость</dt>
									<dd class="mb-0">{{$formatPrice(currentConfiguration.price)}}</dd>
								</dl>
							</div>
							<div class="col-12 col-sm-8 d-flex flex-column">
								<div class="h6 mb-15px">Блоки программы</div>

								<div class="d-flex flex-column gap-2">
									<div v-for="group in bid.program.group_program" :key="`group_${bid.id}_${group.id}`" v-if="group.type === 'mandatory'">
										<ul class="list-unstyled d-flex flex-column gap-1 mb-0" v-if="group.blocks.length">
											<li v-for="block in group.blocks" :key="`block_${bid.id}_${block.id}`">{{block.name}}</li>
										</ul>
									</div>
								</div>
							</div>
						</div>

						<div class="fs-20px fw-600 mb-20px" v-if="!bid.give_consent">Выберите параметры обучения</div>

						<!--<template v-if="bid.program && bid.program.configurations">
							<div class="fs-14px fw-600 mb-12px">Конфигурация обучения:</div>

							<div class="row align-items-stretch mb-10px">
								<div class="col-12 col-md-6 col-lg-4 mb-20px" v-for="config in bid.program.configurations" :key="`config_${config.id}_${bid.id}`">
									<label class="h-100 form-check d-flex border border-gray border-radius-lg py-16px px-16px" :for="`config_${config.id}_${bid.id}`">
										<input class="form-check-input form-check-input-bordered ms-0" type="radio" v-model="form.configuration_id" :name="`config_${bid.id}`" :value="config.id" :id="`config_${config.id}_${bid.id}`">
										<div class="form-check-label ms-12px">
											<div class="fs-14px fw-500">{{config.study_form.name}} {{config.hours_volume}} а.ч.</div>
										</div>
									</label>
								</div>
							</div>
						</template>-->

						<div class="row mb-15px align-items-end">
							<div class="col-12 col-sm-6 col-lg-3">
								<div class="fs-14px fw-600 mb-12px">Формат обучения</div>
								<ValidationProvider tag="div" class="col-12 mb-15px position-relative" rules="required" name="Формат обучения" v-slot="{ errors }">
									<multiselect
											v-model="form.training_format_id"
											:options="training_formats.map(el => el.id)"
											:custom-label="opt => getLabel(training_formats, opt, 'id')"
											:show-labels="false"
											:searchable="false"
											:allow-empty="false"
											:placeholder="``"
											:disabled="bid.give_consent"
									></multiselect>
								</ValidationProvider>
							</div>
							<div class="col-12 col-sm-6 col-lg-3">
								<div class="fs-14px fw-600 mb-12px">Даты потоков обучения</div>
								<ValidationProvider tag="div" class="col-12 mb-15px position-relative" rules="required" name="Даты потоков обучения" v-slot="{ errors }">
									<multiselect
											v-model="form.thread_id"
											:options="threads.map(el => el.id)"
											:custom-label="opt => getLabel(threads, opt, 'id')"
											:show-labels="false"
											:searchable="false"
											:allow-empty="false"
											:placeholder="``"
											:disabled="bid.give_consent"
									></multiselect>
								</ValidationProvider>
							</div>
							<div class="col-12 col-sm-6 col-lg-3">
								<div class="fs-14px fw-600 mb-12px">Объем учебной нагрузки в день</div>
								<ValidationProvider tag="div" class="col-12 mb-15px position-relative" rules="required" name="Объем учебной нагрузки в день" v-slot="{ errors }">
									<multiselect
											v-model="form.volume_academic_workload"
											:options="volume_academic_workload.map(el => el.id)"
											:custom-label="opt => getLabel(volume_academic_workload, opt, 'id')"
											:show-labels="false"
											:searchable="false"
											:allow-empty="false"
											:placeholder="``"
											:disabled="bid.give_consent"
									></multiselect>
								</ValidationProvider>
							</div>
							<div class="col-12 col-sm-6 col-lg-3">
								<div class="fs-14px fw-600 mb-12px">Режим занятий</div>
								<ValidationProvider tag="div" class="col-12 mb-15px position-relative" rules="required" name="Режим занятий" v-slot="{ errors }">
									<multiselect
											v-model="form.training_regimes"
											:options="training_regimes.map(el => el.id)"
											:custom-label="opt => getLabel(training_regimes, opt, 'id')"
											:multiple="true"
											:close-on-select="false"
											:show-labels="false"
											:searchable="false"
											:allow-empty="false"
											:placeholder="``"
											:disabled="bid.give_consent">
										<template slot="selection" slot-scope="{ values, search, isOpen }">
											<span class="multiselect__single" v-if="values.length">{{getLabels(training_regimes, values, 'id')}}</span>
										</template>
									</multiselect>
								</ValidationProvider>
							</div>
						</div>

						<template v-if="currentConfiguration">
							<div class="fs-14px fw-600 mb-12px">Дополнительные блоки:</div>

							<div class="row align-items-stretch mb-10px">
								<div class="col-12 col-md-6 col-lg-4 mb-20px" v-for="group in currentConfiguration.group_program" :key="`dop_group_${group.id}_${bid.id}`">
									<label class="h-100 form-check d-flex bg-gray-10 border-radius-lg py-16px px-16px" :for="`dop_group_${group.id}_${bid.id}`">
										<input class="form-check-input form-check-input-bordered ms-0"
											   type="checkbox"
											   v-model="form.group_program_id"
											   :name="`dop_group_${group.id}_${bid.id}`"
											   :value="group.id"
											   :id="`dop_group_${group.id}_${bid.id}`"
											   v-if="!bid.give_consent" />
										<span :disabled="true"
											  :class="`form-check-input checked ms-0 form-check-input-bordered`"
											  v-else />
										<div class="form-check-label ms-12px">
											<div class="fs-14px fw-500 mb-8px">{{group.name}}</div>
											<ul class="list-unstyled mb-0 d-flex flex-column gap-2" v-if="group.blocks.length">
												<li v-for="block in group.blocks" :key="`block_${bid.id}_${block.id}`">{{block.name}}</li>
											</ul>
										</div>
									</label>
								</div>
							</div>
						</template>

						<!--<div class="fs-18px fw-600 mb-12px">Сервисы:</div>

						<div class="d-flex flex-wrap flex-rows align-items-stretch mb-30px">
							<label class="flex-col-4 form-check d-flex bg-gray-10 border-radius-lg py-16px px-16px" for="flexRadioDefault21">
								<input class="form-check-input form-check-input-bordered ms-0" type="checkbox" name="flexRadioDefault2" id="flexRadioDefault21">
								<div class="form-check-label ms-12px flex-1">
									<div class="d-flex justify-content-between fw-600 w-100 gap-2">
										<span>Кофебрейк</span>
										<span>10 000 Р</span>
									</div>
									<div class="mt-12px text-gray-600">
										Краткое описание
									</div>
								</div>
							</label>
							<label class="flex-col-4 form-check d-flex bg-gray-10 border-radius-lg py-16px px-16px" for="flexRadioDefault22">
								<input class="form-check-input form-check-input-bordered ms-0" type="checkbox" name="flexRadioDefault2" id="flexRadioDefault22">
								<div class="form-check-label ms-12px flex-1">
									<div class="d-flex justify-content-between fw-600 w-100 gap-2">
										<span>Раздаточные материалы</span>
										<span>2 000 Р</span>
									</div>
									<div class="mt-12px text-gray-600">
										Краткое описание
									</div>
								</div>
							</label>
							<label class="flex-col-4 form-check d-flex bg-gray-10 border-radius-lg py-16px px-16px" for="flexRadioDefault23">
								<input class="form-check-input form-check-input-bordered ms-0" type="checkbox" name="flexRadioDefault2" id="flexRadioDefault23">
								<div class="form-check-label ms-12px flex-1">
									<div class="d-flex justify-content-between fw-600 w-100 gap-2">
										<span>Кофебрейк</span>
										<span>10 000 Р</span>
									</div>
									<div class="mt-12px text-gray-600">
										Краткое описание
									</div>
								</div>
							</label>
						</div>-->

						<div class="mb-30px">
							<div class="fs-16px fw-600 mb-12px">Программой предусмотрено предоставление мотивационного письма</div>

							<a :href="bid.motivation_letter.file_link" :download="bid.motivation_letter.file_name" class="text-decoration-underline" v-if="bid.motivation_letter && bid.motivation_letter.id">{{bid.motivation_letter.file_name}}</a>
							<AddMotivationLetterModal :id-program="_id" @setFile="setFile" v-else>
								<template slot="button">
									<a href="javascript:;" class="text-decoration-underline">{{form.motivation_letter ? form.motivation_letter.name : 'Правила предоставления мотивационного письма'}}</a>
								</template>
							</AddMotivationLetterModal>
						</div>

						<!--<div class="fs-16px fw-600 mb-12px">Предоставить возможность отслеживать обучение контактному лицу</div>

						<div class="d-flex flex-column gap-3">
							<div :class="'position-relative form-check'">
								<input type="checkbox"
									   v-model="form.progress_training"
									   :name="`form-field-progress_training`"
									   :id="`form-field-progress_training`"
									   class="form-check-input form-check-input-bordered" />
								<label :for="`form-field-progress_training`"
									   class="form-label ms-12px mb-0">ход обучение (прогресс)</label>
							</div>
							<div :class="'position-relative form-check'">
								<input type="checkbox"
									   v-model="form.extracts_from_orders"
									   :name="`form-field-extracts_from_orders`"
									   :id="`form-field-extracts_from_orders`"
									   class="form-check-input form-check-input-bordered" />
								<label :for="`form-field-extracts_from_orders`"
									   class="form-label ms-12px mb-0">выписки из приказов о зачислении и об отчислении</label>
							</div>
							<div :class="'position-relative form-check'">
								<input type="checkbox"
									   v-model="form.progress_report"
									   :name="`form-field-progress_report`"
									   :id="`form-field-progress_report`"
									   class="form-check-input form-check-input-bordered" />
								<label :for="`form-field-progress_report`"
									   class="form-label ms-12px mb-0">ведомость успеваемости</label>
							</div>
							<div :class="'position-relative form-check'">
								<input type="checkbox"
									   v-model="form.attendance_sheet"
									   :name="`form-field-attendance_sheet`"
									   :id="`form-field-attendance_sheet`"
									   class="form-check-input form-check-input-bordered" />
								<label :for="`form-field-attendance_sheet`"
									   class="form-label ms-12px mb-0">табель посещаемости</label>
							</div>
							<div :class="'position-relative form-check'">
								<input type="checkbox"
									   v-model="form.documents_on_completion"
									   :name="`form-field-documents_on_completion`"
									   :id="`form-field-documents_on_completion`"
									   class="form-check-input form-check-input-bordered" />
								<label :for="`form-field-documents_on_completion`"
									   class="form-label ms-12px mb-0">документы по итогу обучения (Диплом/Удостоверение/Справка об обучении)</label>
							</div>
						</div>-->

						<div class="d-flex flex-wrap gap-4 mt-30px" v-if="!bid.give_consent">
							<button type="submit" class="btn btn-primary px-30px py-10px" :disabled="!form.consent">Отправить</button>

							<!--<router-link :to="''" class="btn btn-outline-default text-dark px-30px py-10px">Подробнее</router-link>-->
						</div>

					</ValidationObserver>
				</div>
			</b-collapse>
		</div>
	</div>
</template>

<script>
  import IconChevronDown from '@/components/icons/IconChevronDown'
  import InputField from '@/components/elements/Input'
  import AddMotivationLetterModal from '@/components/parts/education/AddMotivationLetterModal'
  import uniqid from 'uniqid'
  import axios from 'axios'

  export default {
    name: 'EducationBidCard',
    components: { AddMotivationLetterModal, InputField, IconChevronDown },
    props: ['bid'],
    data() {
      return {
        visible: false,
        form: {
          consent: false,
          thread_id: null,
          training_format_id: null,
          volume_academic_workload: null,
          training_regimes: [],
          progress_training: false,
          extracts_from_orders: false,
          documents_on_completion: false,
          attendance_sheet: false,
          progress_report: false,
          motivation_letter: null,
          group_program_id: [],
          configuration_id: null,
        },
        volume_academic_workload: [
          { id: 8, label: '8 а.ч.' },
          { id: 9, label: '9 а.ч.' },
          { id: 10, label: '10 а.ч.' },
          { id: 11, label: '11 а.ч.' },
          { id: 12, label: '12 а.ч.' },
        ],
        training_regimes: [
          { id: 1, label: 'Понедельник' },
          { id: 2, label: 'Вторник' },
          { id: 3, label: 'Среда' },
          { id: 4, label: 'Четверг' },
          { id: 5, label: 'Пятница' },
          { id: 6, label: 'Суббота' },
          { id: 7, label: 'Воскресенье' },
        ],
      }
    },
    computed: {
      _id() {
        return uniqid()
      },
      notMandatoryGroups() {
        let groups
        if (this.bid && this.bid.program.group_program) {
          groups = []
          this.bid.program.group_program.forEach(group => {
            if (group.type === 'not_mandatory') {
              groups.push(group)
            }
          })
        }
        return groups
      },
      currentConfiguration() {
        let configuration
        if (this.bid && this.bid.program.configurations) {
          this.bid.program.configurations.forEach(item => {
            if (item.id === this.form.configuration_id) {
              configuration = item
              return false
            }
          })
        }
        return configuration
      },
      training_formats() {
        let items = []
        if (this.currentConfiguration) {
          this.currentConfiguration.training_formats.forEach(item => {
            items.push({
              id: item.id,
              label: item.name,
            })
          })
        }
        return items
      },
      threads() {
        let items = []
        if (this.bid && this.bid.program) {
          this.bid.program.threads.forEach(item => {
            items.push({
              id: item.id,
              label: `${item.name}, ${item.date_start} - ${item.date_end}`,
            })
          })
        }
        return items
      },
      price() {
        let price = 0
        if (this.bid && this.bid.program && this.bid.program.group_program) {
          this.bid.program.group_program.forEach(group => {
            if (group.blocks.length) {
              group.blocks.forEach(block => {
                price += block.price
              })
            }
          })
        }
        return price
      },
      token() {
        return this.$store.getters['auth/token']
      },
    },
    watch: {
      bid(newVal) {
        this.update()
      },
    },
    mounted() {
      if (this.bid) {
        this.visible = !this.bid.give_consent
        this.form.consent = this.bid.give_consent
        this.update()
      }
    },
    methods: {
      getLabel(list, value, field) {
        let label = ''
        if (list && list.length) {
          list.forEach(item => {
            if (item[field].toString() === value.toString()) {
              label = item.label
            }
          })
        }
        return label
      },
      getLabels(list, values, field) {
        let label = []
        values = values.map(val => val.toString())
        if (list && list.length) {
          list.forEach(item => {
            if (values.indexOf(item[field].toString()) >= 0) {
              label.push(item.label)
            }
          })
        }
        if (label.length > 0) {
          label = label.join(', ')
        } else {
          label = ''
        }
        return label
      },
      update() {
        this.visible = !this.bid.give_consent
        this.form.consent = this.bid.give_consent
        this.form.configuration_id = this.bid.configuration.id
        this.form.training_format_id = this.bid.training_format?.id || null
        this.form.thread_id = this.bid.thread?.id || null
        this.form.training_regimes = this.bid.training_regimes ? this.bid.training_regimes.map(item => item.day) : []
        this.form.volume_academic_workload = this.bid.volume_academic_workload
        this.form.group_program_id = this.bid.program_blocks ? this.bid.program_blocks.map(item => item.group_id) : []
        this.form.group_program_id = this.form.group_program_id.filter((item, index) => this.form.group_program_id.indexOf(item) === index)
      },
      setFile(file) {
        console.log('file', file)
        this.form.motivation_letter = file
      },
      async submitForm() {
        const isValid = await this.$refs['observer-form'].validate()
        if (!isValid) return false

        let errors = []

        if (!this.form.motivation_letter) {
          errors.push('Прикрепите мотивационное письмо')
        }

        if (!this.form.training_format_id) {
          errors.push('Выберите формат обучение')
        }
        if (!this.form.thread_id) {
          errors.push('Выберите поток')
        }
        if (!this.form.training_regimes.length) {
          errors.push('Выберите режим занятий')
        }
        if (!this.form.volume_academic_workload) {
          errors.push('Выберите объем учебной нагрузки в день')
        }

        if (errors.length) {
          this.$notify({
            group: 'center',
            title: ``,
            text: errors.join('<br/>'),
            type: 'error',
          })
          return false
        }

        let formData = new FormData()

        this.form.training_regimes.forEach(item => {
          formData.append('training_regimes[]', item)
        })
        this.form.group_program_id.forEach(item => {
          formData.append(`group_program_id[${item}]`, 1)
        })
        formData.append('volume_academic_workload', this.form.volume_academic_workload)
        formData.append('training_format_id', this.form.training_format_id)
        formData.append('thread_id', this.form.thread_id)
        //formData.append('configuration_id', this.form.configuration_id)
        formData.append('motivation_letter', this.form.motivation_letter)
        formData.append('consent', this.form.consent ? 1 : 0)

        let path = `/listeners/profile/bids/${this.bid.id}/confirm/send`

        axios({
          url: process.env.VUE_APP_API_URL + path,
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.token}`,
          },
          data: formData,
        })
          .then(res => res.data)
          .then(res => {
            if (res.message) {
              this.$notify({
                group: 'center',
                title: `Ошибка!`,
                text: res.message,
                type: 'error',
              })
            } else {
              this.$store.dispatch('bids/fetchBidItems')
              this.$bvModal.show('BidsMessageModal')
            }
          })
      },
    },
  }
</script>
