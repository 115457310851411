<template>
	<div>
		<!-- BEGIN page-header -->
		<div class="d-flex flex-wrap flex-row align-items-center justify-content-between">
			<h1 class="page-header">Мои данные</h1>
		</div>
		<!-- END page-header -->

		<!-- START content -->
		<div class="d-flex mb-24px">
			<button
					class="me-20px"
					:class="'btn' + (index === selectedTabIndex ? ' btn-primary' : '  btn-outline-primary')"
					v-for="(tab, index) in tabs"
					:key="`tab_${index}`"
					@click="selectTab(index)">{{tab}}
			</button>
		</div>

		<div class="mw-1200px">
			<!-- START TAB 1 -->
			<ListenerQuestionnaire v-show="selectedTabIndex === 0" @goToDocuments="selectedTabIndex = 1" />
			<!-- END TAB 1 -->
			<!-- START TAB 2 -->
			<PersonalDataDocumentsForm v-show="selectedTabIndex === 1" />
			<!-- END TAB 2 -->
			<!-- START TAB 3 -->
			<PersonalDataOtherDocumentsForm v-show="selectedTabIndex === 2" />
			<!-- END TAB 3 -->
		</div>
		<!-- END content -->

		<!-- START page notifications -->
		<notifications group="center" position="top center" :duration="10000">
			<template slot="body" slot-scope="props">
				<div :class="'vue-notification-template vue-notification ' + props.item.type">
					<div class="title">
						{{props.item.title}}
					</div>
					<a class="close" @click="props.close">
						<i class="fa fa-times"></i>
					</a>
					<div v-html="props.item.text">
					</div>
				</div>
			</template>
		</notifications>
		<!-- END page notifications -->
	</div>
</template>

<script>
  import InputField from '@/components/elements/Input'
  import InputFileField from '@/components/elements/InputFile'
  import IconDocuments from '@/components/icons/IconDocuments'
  import PersonalDataProfileForm from '@/components/parts/profile/PersonalDataProfileForm'
  import PersonalDataOtherDocumentsForm from '@/components/parts/profile/PersonalDataOtherDocumentsForm'
  import PersonalDataDocumentsForm from '@/components/parts/profile/PersonalDataDocumentsForm'
  import ListenerQuestionnaire from '@/components/parts/profile/ListenerQuestionnaire'

  export default {
    name: 'ListenerProfile',
    components: { ListenerQuestionnaire, PersonalDataDocumentsForm, PersonalDataOtherDocumentsForm, PersonalDataProfileForm, IconDocuments, InputField, InputFileField },
    data() {
      return {
        tabs: ['Персональные данные', 'Документы для личного дела', 'Иные документы'],
        selectedTabIndex: 0,
      }
    },
    computed: {
      user() {
        return this.$store.getters['user/user']
      },
      roles() {
        return this.user ?  this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
      },
      isListener() {
        return this.user && this.roles.indexOf('listener') >= 0
      },
    },
    mounted() {
      if (this.$route.hash === '#documents') {
        this.selectedTabIndex = 1
	  }
    },
    methods: {
      selectTab(index) {
        this.selectedTabIndex = index
      },
    },
  }
</script>
