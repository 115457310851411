import state from './referralState'
import mutations from './referralMutations'
import getters from './referralGetters'
import actions from './referralActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
