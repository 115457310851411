<template>
	<div>
		<!-- BEGIN page-header -->
		<div class="d-flex flex-wrap flex-row align-items-center justify-content-between">
			<h1 class="page-header">Заказы на обучение</h1>
		</div>
		<!-- END page-header -->
		<slot name="filter"></slot>
		<div class="mw-1200px">
			<div class="table-responsive-md" v-if="items && items.length">
				<div class="table-outer-radius">
					<table class="table table-bordered mb-0 align-middle">
						<thead>
						<tr>
							<th>№<br/>Заказа</th>
							<th>Дата<br/>и время</th>
							<th>Контрагент</th>
							<th>ИНН</th>
							<th>КПП</th>
							<th>Тип<br/>контрагента</th>
							<th>Сумма заказа</th>
							<th>ДЗ</th>
							<th>Реф. ссылка</th>
							<th>№<br/>Заявки</th>
							<th>Номер<br/>договора</th>
							<th>Сумма<br/>договора</th>
							<th class="w-100px"></th>
							<th class="w-50px"></th>
						</tr>
						</thead>
						<tbody>
						<template v-for="item in items">
							<tr :key="item.id">
								<td :rowspan="item.bids.length ? item.bids.length : false" class="align-top">{{item.id}}</td>
								<td :rowspan="item.bids.length ? item.bids.length : false" class="align-top">{{item.created_at}}</td>
								<td :rowspan="item.bids.length ? item.bids.length : false" class="align-top">{{item.contr_agent?.abbreviation}}</td>
								<td :rowspan="item.bids.length ? item.bids.length : false" class="align-top">{{item.contr_agent?.inn}}</td>
								<td :rowspan="item.bids.length ? item.bids.length : false" class="align-top">{{item.contr_agent?.kpp}}</td>
								<td :rowspan="item.bids.length ? item.bids.length : false" class="align-top">{{getTypeFaceName(item.contr_agent?.type_face)}}</td>
								<td :rowspan="item.bids.length ? item.bids.length : false" class="align-top">{{item.price}}</td>
								<td :rowspan="item.bids.length ? item.bids.length : false" class="align-top">{{item.priceDiscont}}</td>
								<td :rowspan="item.bids.length ? item.bids.length : false" class="align-top">{{[item.referral?.surname,item.referral?.name, item.referral?.patronymic].join(' ')}}</td>
								<template v-for="(bid, index) in item.bids" v-if="index === 0 && item.bids.length ? item.bids.length : false">
									<td :key="`item_${item.id}_bid_${bid.id}`" class="align-top">{{bid.id}}</td>
									<td class="align-top">{{bid.contract_number}}</td>
									<td class="align-top">{{bid.sum}}</td>
									<td class="align-top">
										<router-link :to="`/orders/${item.id}/bids/${bid.id}`" class="text-decoration-underline">Подробнее</router-link>
									</td>
								</template>
								<template v-if="!item.bids.length">
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</template>
								<td :rowspan="item.bids.length ? item.bids.length : false" class="align-top">
									<div class="form-check d-flex align-items-center justify-content-center my-4px">
										<input type="checkbox"
											   v-model="item.checked"
											   :id="`table_checkbox_${item?.id}`"
											   class="form-check-input form-check-input-bordered">
									</div>
								</td>
							</tr>
							<tr v-for="(bid, index) in item.bids" :key="`item_${item.id}_bid_${bid.id}`" v-if="index !== 0 && item.bids.length ? item.bids.length : false">
								<td>{{bid.id}}</td>
								<td>{{bid.contract_number}}</td>
								<td>{{bid.sum}}</td>
								<td class="align-top">
									<router-link :to="`/orders/${item.id}/bids/${bid.id}`" class="text-decoration-underline">Подробнее</router-link>
								</td>
							</tr>
						</template>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	const TYPE_FACE_NAMES = {
	  'fiz': 'Физ. лицо',
	  'ur': 'Юр. лицо',
	  'ip': 'ИП'
	}

  export default {
    name: 'MopRopOrderList',
    props: ['items'],
    data() {
      return {}
    },
    computed: {},
    created() {
    },
    mounted() {
    },
    methods: {
      getTypeFaceName(code) {
        if (code) {
          return TYPE_FACE_NAMES[code.toLowerCase()]
        }
	  }
	},
  }
</script>
