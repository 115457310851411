export default {
  RESET_NOTIFICATIONS_ITEMS(state) {
    state = {
      items: [],
      pagination: {
        last_page: 1,
      }
    }
  },
  SET_NOTIFICATIONS_ITEMS(state, payload){
    if (payload.data) payload = payload.data
    state.items = payload.items
    state.pagination.last_page = payload.pagination ? payload.pagination.page_count : 1
    state.pagination.items_count = payload.pagination ? payload.pagination.items_count : 0
  },
  SET_UNREAD_NOTIFICATIONS_ITEMS(state, payload) {
    if (payload.data) payload = payload.data
    state.unread = payload.length >= 1
  },
  SET_NEW_NOTIFICATION(state, payload) {
    state.unread = payload
  }
}
