<template>
	<div class="mw-900px">
		<div class="alert alert-success d-flex align-items-center mb-24px" v-if="user.profile_verified">
			<i class="fa fa-check me-12px"></i>
			Поля анкеты проверены
		</div>
		<ValidationObserver tag="form"
							@submit.prevent="sendForm"
							ref="observer-data-form"
							v-slot="{ invalid }"
							method="POST">


			<div class="row">
				<ValidationProvider tag="div" class="col-12 col-sm-6 mb-15px position-relative" rules="required" name="Фамилия" mode="lazy" v-slot="{ errors }">
					<InputField
							:type-field="`text`"
							:errors-field="errors"
							:name-field="`Фамилия*`"
							:filter-field="`[0-9а-яА-ЯёЁa-zA-Z \-]`"
							:placeholder-field="`Введите фамилию`"
							v-model="form.surname"

					/>
				</ValidationProvider>

				<ValidationProvider tag="div" class="col-12 col-sm-6 mb-15px position-relative" rules="required" name="Имя" mode="lazy" v-slot="{ errors }">
					<InputField
							:type-field="`text`"
							:errors-field="errors"
							:name-field="`Имя*`"
							:filter-field="`[0-9а-яА-ЯёЁa-zA-Z \-]`"
							:placeholder-field="`Введите имя`"
							v-model="form.name"

					/>
				</ValidationProvider>

				<ValidationProvider tag="div" class="col-12 col-sm-6 mb-15px position-relative" rules="" name="Отчество" mode="lazy" v-slot="{ errors }">
					<InputField
							:type-field="`text`"
							:errors-field="errors"
							:name-field="`Отчество`"
							:filter-field="`[0-9а-яА-ЯёЁa-zA-Z \-]`"
							:placeholder-field="`Введите отчество`"
							v-model="form.patronymic"

					/>
				</ValidationProvider>

				<ValidationProvider tag="div" class="col-12 col-sm-6 mb-15px position-relative" rules="required|snils" name="СНИЛС" mode="lazy" v-slot="{ errors }">

					<label for="form-program-field-snils" class="form-label">СНИЛС*</label>

					<masked-input type="text"
								  class="form-control"
								  :class="errors.length ? 'is-invalid' : ''"
								  placeholder="___-___-___-__"
								  name="form-program-field-snils"
								  id="form-program-field-snils"
								  pattern="111-111-111-11"

								  v-model="form['snils']">

					</masked-input>

					<div v-if="errors.length" class="invalid-tooltip">
						<span v-for="err in errors" :key="'form-program-field-snils_err'">{{err}}</span>
					</div>

				</ValidationProvider>

				<ValidationProvider tag="div" class="col-12 col-sm-6 mb-15px position-relative" rules="required|phone" name="Телефон" mode="lazy" v-slot="{ errors }">

					<label for="form-program-field-phone" class="form-label">Телефон*</label>

					<masked-input type="text"
								  class="form-control"
								  :class="errors.length ? 'is-invalid' : ''"
								  :placeholder="'+7 (999) 999-99-99'"
								  :name="'form-program-field-phone'"
								  id="form-program-field-phone"
								  :pattern="'+7 (111) 111-11-11'"

								  v-model="form['phone']">

					</masked-input>

					<div v-if="errors.length" class="invalid-tooltip">
						<span v-for="err in errors" :key="'form-program-field-phone_err'">{{err}}</span>
					</div>

				</ValidationProvider>

				<ValidationProvider tag="div" class="col-12 col-sm-6 mb-15px position-relative" rules="required|email" name="Эл. адрес" mode="lazy" v-slot="{ errors }">
					<InputField
							:type-field="`text`"
							:errors-field="errors"
							:name-field="`Эл. адрес*`"
							:placeholder-field="`example@mail.ru`"
							:filter-field="`[0-9a-z@_\.]`"

							v-model="form.email"
					/>
				</ValidationProvider>
			</div>

			<button type="submit" class="btn btn-primary px-30px py-10px mt-15px" :disabled="!hasChanges">Отправить на проверку</button>

		</ValidationObserver>
	</div>
</template>

<script>
  import InputField from '@/components/elements/Input'

  export default {
    name: 'PersonalDataProfileForm',
    components: { InputField },
    data() {
      return {
        form: {
          surname: '',
          name: '',
          patronymic: '',
          phone: '',
          email: '',
          snils: '',
        },
      }
    },
    computed: {
      user() {
        return this.$store.getters['user/user']
      },
      roles() {
        return this.user ?  this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
      },
      isListener() {
        return this.user && this.roles.indexOf('listener') >= 0
      },
      personalData() {
        return JSON.parse(JSON.stringify(this.$store.getters['user/personal_data']))
      },
	  hasChanges() {
        let hasChanges = false
        let tmp = JSON.parse(JSON.stringify(this.form))
        if (tmp.snils) {
          tmp.snils = tmp.snils.replace(/[^\d]/g, '')
        }
        if (tmp.phone) {
          tmp.phone = tmp.phone.replace(/[^\d+]/g, '')
        }
        console.log('this.personalData', this.personalData, tmp)
		if (this.personalData) {
          for (let key in tmp) {
            if (tmp.hasOwnProperty(key) && this.personalData[key] !== tmp[key]) {
              hasChanges = true
            }
          }
        }
		return hasChanges
	  },
    },
    watch: {
      personalData(newVal, oldVal) {
        this.form.surname = newVal.surname
        this.form.name = newVal.name
        this.form.patronymic = newVal.patronymic
        this.form.phone = this.$formatPhone(newVal.phone)
        this.form.email = newVal.email
        this.form.snils = newVal.snils.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/g, `$1-$2-$3-$4`)
      },
    },
    mounted() {
      this.$store.dispatch('user/fetchGetUserPersonalData')
    },
	methods: {
      formatForm() {
        let tmp = JSON.parse(JSON.stringify(this.form))
        if (tmp.snils) {
          tmp.snils = tmp.snils.replace(/[^\d]/g, '')
        }
        if (tmp.phone) {
          tmp.phone = tmp.phone.replace(/[^\d+]/g, '')
        }
        return tmp
	  },
      async sendForm() {
        const isValid = await this.$refs['observer-data-form'].validate()
		if (!isValid) return
		console.log('this.hasChanges)', this.hasChanges)
		if (this.hasChanges) {
          let data = this.formatForm()
          this.$store.dispatch('user/fetchUpdateUserPersonalData', data).then(res => {
            if (res.message) {
              this.$notify({
                group: 'center',
                title: `Ошибка!`,
                text: res.message,
                type: 'error',
              })
			} else {
              this.$notify({
                group: 'center',
                title: ``,
                text: `Отправлено на проверку`,
                type: 'success',
              })
            }
          })
		}
	  }
	}
  }
</script>
