import DashboardV1 from '@/pages/admin-test/Dashboard-v1.vue'
import DashboardV2 from '@/pages/admin-test/Dashboard-v2.vue'
import DashboardV3 from '@/pages/admin-test/Dashboard-v3.vue'
import EmailInbox from '@/pages/admin-test/Email-inbox.vue'
import EmailCompose from '@/pages/admin-test/Email-compose.vue'
import EmailDetail from '@/pages/admin-test/Email-detail.vue'
import Widgets from '@/pages/admin-test/Widgets.vue'
import UIGeneral from '@/pages/admin-test/UI-general.vue'
import UITypography from '@/pages/admin-test/UI-typography.vue'
import UITabsAccordion from '@/pages/admin-test/UI-tabs-accordion.vue'
import UIModalNotification from '@/pages/admin-test/UI-modal-notification.vue'
import UIWidgetBoxes from '@/pages/admin-test/UI-widget-boxes.vue'
import UIMediaObject from '@/pages/admin-test/UI-media-object.vue'
import UIButtons from '@/pages/admin-test/UI-buttons.vue'
import UIIcons from '@/pages/admin-test/UI-icons.vue'
import UISimpleLineIcons from '@/pages/admin-test/UI-simple-line-icons.vue'
import UIIonicons from '@/pages/admin-test/UI-ionicons.vue'
import UILanguageBarIcon from '@/pages/admin-test/UI-language-bar-icon.vue'
import UISocialButtons from '@/pages/admin-test/UI-social-buttons.vue'
import Bootstrap5 from '@/pages/admin-test/Bootstrap-5.vue'
import FormElements from '@/pages/admin-test/Form-elements.vue'
import FormPlugins from '@/pages/admin-test/Form-plugins.vue'
import FormWizards from '@/pages/admin-test/Form-wizards.vue'
import PosCounterCheckout from '@/pages/admin-test/Pos-counter-checkout.vue'
import PosCustomerOrder from '@/pages/admin-test/Pos-customer-order.vue'
import PosKitchenOrder from '@/pages/admin-test/Pos-kitchen-order.vue'
import PosMenuStock from '@/pages/admin-test/Pos-menu-stock.vue'
import PosTableBooking from '@/pages/admin-test/Pos-table-booking.vue'
import TableBasic from '@/pages/admin-test/Table-basic.vue'
import TableData from '@/pages/admin-test/Table-data.vue'
import ChartJs from '@/pages/admin-test/Chart-js.vue'
import ChartD3 from '@/pages/admin-test/Chart-d3.vue'
import ChartApex from '@/pages/admin-test/Chart-apex.vue'
import Calendar from '@/pages/admin-test/Calendar.vue'
import Map from '@/pages/admin-test/Map.vue'
import Gallery from '@/pages/admin-test/Gallery.vue'
import PageBlank from '@/pages/admin-test/Page-blank.vue'
import PageWithFooter from '@/pages/admin-test/Page-with-footer.vue'
import PageWithFixedFooter from '@/pages/admin-test/Page-with-fixed-footer.vue'
import PageWithoutSidebar from '@/pages/admin-test/Page-without-sidebar.vue'
import PageWithRightSidebar from '@/pages/admin-test/Page-with-right-sidebar.vue'
import PageWithMinifiedSidebar from '@/pages/admin-test/Page-with-minified-sidebar.vue'
import PageWithTwoSidebar from '@/pages/admin-test/Page-with-two-sidebar.vue'
import PageFullHeight from '@/pages/admin-test/Page-full-height.vue'
import PageWithWideSidebar from '@/pages/admin-test/Page-with-wide-sidebar.vue'
import PageWithLightSidebar from '@/pages/admin-test/Page-with-light-sidebar.vue'
import PageWithMegaMenu from '@/pages/admin-test/Page-with-mega-menu.vue'
import PageWithTopMenu from '@/pages/admin-test/Page-with-top-menu.vue'
import PageWithBoxedLayout from '@/pages/admin-test/Page-with-boxed-layout.vue'
import PageWithMixedMenu from '@/pages/admin-test/Page-with-mixed-menu.vue'
import PageBoxedLayoutWithMixedMenu from '@/pages/admin-test/Page-boxed-layout-with-mixed-menu.vue'
import PageWithTransparentSidebar from '@/pages/admin-test/Page-with-transparent-sidebar.vue'
import PageWithSearchSidebar from '@/pages/admin-test/Page-with-search-sidebar.vue'
import ExtraTimeline from '@/pages/admin-test/Extra-timeline.vue'
import ExtraComingSoon from '@/pages/admin-test/Extra-coming-soon.vue'
import ExtraSearch from '@/pages/admin-test/Extra-search.vue'
import ExtraInvoice from '@/pages/admin-test/Extra-invoice.vue'
import ExtraError from '@/pages/admin-test/Extra-error.vue'
import ExtraProfile from '@/pages/admin-test/Extra-profile.vue'
import ExtraScrumBoard from '@/pages/admin-test/Extra-scrum-board.vue'
import ExtraCookieAcceptanceBanner from '@/pages/admin-test/Extra-cookie-acceptance-banner.vue'
import HelperCss from '@/pages/admin-test/Helper-css'

import Home from '@/pages/Home.vue'
import OrdersList from '@/pages/orders/Orders-list.vue'
import OrderDetail from '@/pages/orders/Order-detail'
import OrderBidDetail from '@/pages/orders/Order-bid-detail'
import OrderAddProgram from '@/pages/orders/Order-add-program'
import OrderAddListener from '@/pages/orders/Order-add-listener'

import UserLogin from '@/pages/User-login.vue'
import UserForgotPassword from '@/pages/User-forgot-password'
import UserRegister from '@/pages/User-register.vue'

import Profile from '@/pages/profiles/Profile'

import Documents from '@/pages/Documents'

import EducationBidsConfirmed from '@/pages/education/EducationBidsConfirmed'
import EducationBids from '@/pages/education/EducationBids'

import Notifications from '@/pages/Notifications'

import DBListeners from '@/pages/dblisteners/DBListeners'
import DBListenersPersonalMatter from '@/pages/dblisteners/DBListenersPersonalMatter'
import DBListenersPersonalDeals from '@/pages/dblisteners/DBListenersPersonalDeals'
import EducationBidsConfirmedDetail from '@/pages/education/EducationBidsConfirmedDetail'
import Tickets from '@/pages/tickets/Tickets'
import TicketsDetail from '@/pages/tickets/TicketsDetail'

const routes = [
  { path: '*', component: ExtraError },
  { path: '/404', name: '404', component: ExtraError },
  { path: '/', name: 'Home', component: Home, meta: { needProfileSuccess: true } },

  { path: '/login', name: 'UserLogin', component: UserLogin },
  { path: '/forgot-password', name: 'UserForgotPassword', component: UserForgotPassword },

  { path: '/notifications', name: 'Notifications', component: Notifications, meta: { needProfileSuccess: true } },

  { path: '/orders', name: 'OrdersList', component: OrdersList, meta: { needProfileSuccess: true } },
  { path: '/orders/:id', name: 'OrderDetail', component: OrderDetail, meta: { needProfileSuccess: true } },
  {
    path: '/orders/:id/bids',
    name: 'OrderBidList',
    redirect: { name: 'OrdersList' },
    component: OrderBidDetail,
    meta: { needProfileSuccess: true },
  },
  { path: '/orders/:id/bids/:id_bid', name: 'OrderBidDetail', component: OrderBidDetail, meta: { needProfileSuccess: true } },
  { path: '/orders/:id/edit-listener', name: 'OrderAddListener', component: OrderAddListener, meta: { needProfileSuccess: true } },
  { path: '/orders/:id/add-listener', name: 'OrderAddListener', component: OrderAddListener, meta: { needProfileSuccess: true } },
  { path: '/orders/:id/add-program', name: 'OrderAddProgram', component: OrderAddProgram, meta: { needProfileSuccess: true } },

  { path: '/profile', name: 'Profile', component: Profile },

  { path: '/documents', name: 'Documents', component: Documents },

  { path: '/education/bids', name: 'EducationBids', component: EducationBids },
  { path: '/education/programs', name: 'EducationBidsConfirmed', component: EducationBidsConfirmed },
  { path: '/education/programs/:id', name: 'EducationBidsConfirmedDetail', component: EducationBidsConfirmedDetail },
  { path: '/education/documents', name: 'DocumentsListener', component: Documents },

  { path: '/listeners', name: 'DBListeners', component: DBListeners },
  { path: '/listeners/:id', name: 'DBListenersPersonalMatter', component: DBListenersPersonalMatter },
  { path: '/listeners/:id/deals', name: 'DBListenersPersonalDeals', component: DBListenersPersonalDeals },

  { path: '/tickets', name: 'Tickets', component: Tickets },
  { path: '/tickets/:id', name: 'TicketsDetail', component: TicketsDetail },

  { path: '/dashboard/v1', component: DashboardV1 },
  { path: '/dashboard/v2', component: DashboardV2 },
  { path: '/dashboard/v3', component: DashboardV3 },
  { path: '/email/inbox', component: EmailInbox },
  { path: '/email/compose', component: EmailCompose },
  { path: '/email/detail', component: EmailDetail },
  { path: '/widgets', component: Widgets },
  { path: '/ui/general', component: UIGeneral },
  { path: '/ui/typography', component: UITypography },
  { path: '/ui/tabs-accordions', component: UITabsAccordion },
  { path: '/ui/modal-notification', component: UIModalNotification },
  { path: '/ui/widget-boxes', component: UIWidgetBoxes },
  { path: '/ui/media-object', component: UIMediaObject },
  { path: '/ui/buttons', component: UIButtons },
  { path: '/ui/icons', component: UIIcons },
  { path: '/ui/simple-line-icons', component: UISimpleLineIcons },
  { path: '/ui/ionicons', component: UIIonicons },
  { path: '/ui/language-bar-icon', component: UILanguageBarIcon },
  { path: '/ui/social-buttons', component: UISocialButtons },
  { path: '/bootstrap-5', component: Bootstrap5 },
  { path: '/form/elements', component: FormElements },
  { path: '/form/plugins', component: FormPlugins },
  { path: '/form/wizards', component: FormWizards },
  { path: '/pos/counter-checkout', component: PosCounterCheckout },
  { path: '/pos/customer-order', component: PosCustomerOrder },
  { path: '/pos/kitchen-order', component: PosKitchenOrder },
  { path: '/pos/menu-stock', component: PosMenuStock },
  { path: '/pos/table-booking', component: PosTableBooking },
  { path: '/table/basic', component: TableBasic },
  { path: '/table/data', component: TableData },
  { path: '/chart/js', component: ChartJs },
  { path: '/chart/d3', component: ChartD3 },
  { path: '/chart/apex', component: ChartApex },
  { path: '/calendar', component: Calendar },
  { path: '/map', component: Map },
  { path: '/page-option/blank', component: PageBlank },
  { path: '/page-option/with-footer', component: PageWithFooter },
  { path: '/page-option/with-fixed-footer', component: PageWithFixedFooter },
  { path: '/page-option/without-sidebar', component: PageWithoutSidebar },
  { path: '/page-option/with-right-sidebar', component: PageWithRightSidebar },
  { path: '/page-option/with-minified-sidebar', component: PageWithMinifiedSidebar },
  { path: '/page-option/with-two-sidebar', component: PageWithTwoSidebar },
  { path: '/page-option/full-height', component: PageFullHeight },
  { path: '/page-option/with-wide-sidebar', component: PageWithWideSidebar },
  { path: '/page-option/with-light-sidebar', component: PageWithLightSidebar },
  { path: '/page-option/with-mega-menu', component: PageWithMegaMenu },
  { path: '/page-option/with-top-menu', component: PageWithTopMenu },
  { path: '/page-option/with-boxed-layout', component: PageWithBoxedLayout },
  { path: '/page-option/with-mixed-menu', component: PageWithMixedMenu },
  { path: '/page-option/boxed-layout-with-mixed-menu', component: PageBoxedLayoutWithMixedMenu },
  { path: '/page-option/with-transparent-sidebar', component: PageWithTransparentSidebar },
  { path: '/page-option/with-search-sidebar', component: PageWithSearchSidebar },
  { path: '/gallery', component: Gallery },
  { path: '/extra/timeline', component: ExtraTimeline },
  { path: '/extra/coming-soon', component: ExtraComingSoon },
  { path: '/extra/search', component: ExtraSearch },
  { path: '/extra/invoice', component: ExtraInvoice },
  { path: '/extra/error', component: ExtraError },
  { path: '/extra/profile', component: ExtraProfile },
  { path: '/extra/scrum-board', component: ExtraScrumBoard },
  { path: '/extra/cookie-acceptance-banner', component: ExtraCookieAcceptanceBanner },
  { path: '/user/register-v3', component: UserRegister },
  { path: '/helper/css', component: HelperCss },
]

export default routes
