import axios from 'axios'

export default {
  async fetchBidItem({ rootState, commit }, payload) {
    try {
      if (!rootState.auth.token) return { error: true }

      let path = ''
      let roles = rootState.user.user ? rootState.user.user.role ? [rootState.user.user.role] : Array.isArray(rootState.user.user.roles) ? rootState.user.user.roles : [rootState.user.user.roles] : []
      roles.forEach(role => {
        switch (role) {
          case 'sales_department_manager':
            path = `/mop/orders/${payload.idOrder}/bids/${payload.idBid}/get`
            break
          case 'head_department_manager':
            path = `/rop/orders/${payload.idOrder}/bids/${payload.idBid}/get`
            break
        }
      })

      const res = await axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
      })
      await res.data ? commit('SET_BID_ITEM', res.data.data) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })
      return res.data ? res.data : res.error
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err
    }
  },
  async fetchBidConfirmedItems({ rootState, commit }, params) {
    try {
      if (!rootState.auth.token) return { error: true }

      let path = ''
      let roles = rootState.user.user ? rootState.user.user.role ? [rootState.user.user.role] : Array.isArray(rootState.user.user.roles) ? rootState.user.user.roles : [rootState.user.user.roles] : []
      roles.forEach(role => {
        switch (role) {
          case 'listener':
            path = `/listeners/profile/programs/list`
            break
        }
      })

      const res = await axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
        data: {
          page: params.page || 1,
        },
      })
      await res.data ? commit('SET_PROGRAMS_ITEMS', res.data.data) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })
      return res.data ? res.data : res.error
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err
    }
  },
  async fetchBidConfirmedItem({ rootState, commit }, id) {
    try {
      if (!rootState.auth.token) return { error: true }

      let path = ''
      let roles = rootState.user.user ? rootState.user.user.role ? [rootState.user.user.role] : Array.isArray(rootState.user.user.roles) ? rootState.user.user.roles : [rootState.user.user.roles] : []
      roles.forEach(role => {
        switch (role) {
          case 'listener':
            path = `/listeners/profile/programs/${id}/get`
            break
        }
      })

      const res = await axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
      })
      await res.data ? commit('SET_CONFIRMED_BID_ITEM', res.data.data) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })
      return res.data ? res.data : res.error
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err
    }
  },
  async fetchBidItems({ rootState, commit }) {
    try {
      if (!rootState.auth.token) return { error: true }

      let path = ''
      let roles = rootState.user.user ? rootState.user.user.role ? [rootState.user.user.role] : Array.isArray(rootState.user.user.roles) ? rootState.user.user.roles : [rootState.user.user.roles] : []
      roles.forEach(role => {
        switch (role) {
          case 'listener':
            path = `/listeners/profile/bids/list`
            break
        }
      })

      const res = await axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
      })
      await res.data ? commit('SET_BID_ITEMS', res.data.data) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })
      return res.data ? res.data : res.error
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err
    }
  },
  async fetchUpdatePayer({ rootState, commit }, params) {
    try {
      let path = ''
      let roles = rootState.user.user ? rootState.user.user.role ? [rootState.user.user.role] : Array.isArray(rootState.user.user.roles) ? rootState.user.user.roles : [rootState.user.user.roles] : []
      roles.forEach(role => {
        switch (role) {
          case 'contact_person':
            path = `/contact_person/orders/${params.order_id}/payer/edit`
            break
          case 'sales_department_manager':
            path = `/mop/orders/${params.order_id}/payer/edit`
            break
          case 'head_department_manager':
            path = `/rop/orders/${params.order_id}/payer/edit`
            break
        }
      })
      if (!rootState.auth.token) return { error: true }
      const res = await axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
        data: params,
      })
      console.log(res.data)
      await res.data ? commit('SET_PAYER_TO_BID', {payer: res.data.data, bid_id: params.bid_id}) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })
      return res.data ? res.data : res.error
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err
    }
  },
  setBidItem({ rootState, commit }, payload) {
    return commit('SET_BID_ITEM', payload.data)
  },
  reset({ rootState, commit }) {
    return commit('RESET_BID_ITEMS', {})
  },
}
