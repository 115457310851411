import axios from 'axios'

export default {
  async fetchGetUser({ rootState, commit }, params) {
    try {
      const res = await axios({
        url: process.env.VUE_APP_API_URL + `/auth/user/get`,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
      })
      const result = await res.data
      result ? commit('SET_USER', result.data) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })
      return result
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err
    }
  },
  async fetchGetUserProfile({ rootState, commit }) {
    try {
      let path = ''
      let roles = rootState.user.user ? rootState.user.user.role ? [rootState.user.user.role] : Array.isArray(rootState.user.user.roles) ? rootState.user.user.roles : [rootState.user.user.roles] : []
      roles.forEach(role => {
        switch (role) {
          case 'listener':
            path = `/users/listener/profile/get`
            break;
          case 'sales_department_manager':
            path = `/users/mop/profile/get`
            break;
          case 'head_department_manager':
            path = `/users/rop/profile/get`
            break;
        }
      })

      const res = await axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
      })
      const result = await res.data
      result ? commit('SET_PROFILE', result.data) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })
      return result
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err
    }
  },
  async fetchUpdateUserProfile({ rootState, commit }, params) {
    try {
      let path = ''
      let roles = rootState.user.user ? rootState.user.user.role ? [rootState.user.user.role] : Array.isArray(rootState.user.user.roles) ? rootState.user.user.roles : [rootState.user.user.roles] : []
      roles.forEach(role => {
        switch (role) {
          case 'listener':
            path = `/users/listener/profile/edit`
            break;
          case 'sales_department_manager':
            path = `/users/mop/profile/edit`
            break;
          case 'head_department_manager':
            path = `/users/rop/profile/edit`
            break;
        }
      })

      const res = await axios({
        url: process.env.VUE_APP_API_URL + `/users/listener/profile/edit`,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
        data: params,
      })
      const result = await res.data
      result ? commit('SET_PROFILE', result.data) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })
      return result
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err
    }
  },
  async fetchGetUserPersonalData({ rootState, commit }) {
    try {
      let path = ''
      let roles = rootState.user.user ? rootState.user.user.role ? [rootState.user.user.role] : Array.isArray(rootState.user.user.roles) ? rootState.user.user.roles : [rootState.user.user.roles] : []
      roles.forEach(role => {
        switch (role) {
          case 'listener':
            path = `/listeners/personal/profile/get`
            break;
        }
      })
      const res = await axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
      })
      const result = await res.data
      result ? commit('SET_PERSONAL_DATA', result.data) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })
      return result
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err
    }
  },
  async fetchUpdateUserPersonalData({ rootState, commit }, params) {
    try {
      let path = ''
      let roles = rootState.user.user ? rootState.user.user.role ? [rootState.user.user.role] : Array.isArray(rootState.user.user.roles) ? rootState.user.user.roles : [rootState.user.user.roles] : []
      roles.forEach(role => {
        switch (role) {
          case 'listener':
            path = `/listeners/personal/profile/edit`
            break;
        }
      })
      const res = await axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
        data: params,
      })
      const result = await res.data
      result ? commit('SET_PERSONAL_DATA', result.data) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })
      return result
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err
    }
  },
  async fetchGetUserMainDocuments({ rootState, commit }) {
    try {
      let path = ''
      let roles = rootState.user.user ? rootState.user.user.role ? [rootState.user.user.role] : Array.isArray(rootState.user.user.roles) ? rootState.user.user.roles : [rootState.user.user.roles] : []
      roles.forEach(role => {
        switch (role) {
          case 'listener':
            path = `/listeners/personal/profile/documents/personal/list`
            break;
        }
      })
      const res = await axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
      })
      const result = await res.data
      result ? commit('SET_MAIN_DOCUMENTS', result.data) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })
      return result
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err
    }
  },
  async fetchUpdateUserMainDocuments({ rootState, commit }, payload) {
    try {
      let path = ''
      let roles = rootState.user.user ? rootState.user.user.role ? [rootState.user.user.role] : Array.isArray(rootState.user.user.roles) ? rootState.user.user.roles : [rootState.user.user.roles] : []
      roles.forEach(role => {
        switch (role) {
          case 'listener':
            path = `/listeners/personal/profile/documents/personal/send_to_verification`
            break;
        }
      })
      const res = await axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
        data: payload
      })
      const result = await res.data
     /* result ? commit('SET_MAIN_DOCUMENTS', result.data) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })*/
      return result
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err
    }
  },
  async fetchGetUserOtherDocuments({ rootState, commit }) {
    try {
      let path = ''
      let roles = rootState.user.user ? rootState.user.user.role ? [rootState.user.user.role] : Array.isArray(rootState.user.user.roles) ? rootState.user.user.roles : [rootState.user.user.roles] : []
      roles.forEach(role => {
        switch (role) {
          case 'listener':
            path = `/listeners/personal/profile/documents/another/list`
            break;
        }
      })
      const res = await axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
      })
      const result = await res.data
      result ? commit('SET_OTHER_DOCUMENTS', result.data) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })
      return result
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err
    }
  },
  async fetchUpdateUserOtherDocuments({ rootState, commit }, payload) {
    try {
      let path = ''
      let roles = rootState.user.user ? rootState.user.user.role ? [rootState.user.user.role] : Array.isArray(rootState.user.user.roles) ? rootState.user.user.roles : [rootState.user.user.roles] : []
      roles.forEach(role => {
        switch (role) {
          case 'listener':
            path = `/listeners/personal/profile/documents/another/send_to_verification`
            break;
        }
      })
      const res = await axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
        data: payload
      })
      const result = await res.data
      /*result ? commit('SET_OTHER_DOCUMENTS', result.data) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })*/
      return result
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err
    }
  },
  setUser({ rootState, commit }, payload) {
    return commit('SET_USER', payload)
  },
  resetUser({ rootState, commit }) {
    return commit('RESET_USER')
  }
}
