import state from './notificationsState'
import mutations from './notificationsMutations'
import getters from './notificationsGetters'
import actions from './notificationsActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
