export default {
  RESET_TICKETS_ITEMS(state) {
    state = {
      items: [],
      pagination: {
        last_page: 1,
      }
    }
  },
  SET_TICKETS_ITEMS(state, payload){
    if (payload.data) payload = payload.data
    state.items = payload.items
    state.pagination.last_page = payload.pagination ? payload.pagination.page_count : 1
    state.pagination.items_count = payload.pagination ? payload.pagination.items_count : 0
  },
  SET_TICKETS_ITEM(state, payload) {
    let tmp = []
    if (state.items.length) {
      state.items.forEach(item => {
        if (item.id.toString() === payload.id.toString()) {
          item = {
            ...item,
            ...payload,
          }
        }
        tmp.push(item)
      })
    } else  {
      tmp.push(payload)
    }
    state.items = tmp
  },
  SET_MESSAGES_TO_TICKETS_ITEM(state, payload) {
    let tmp = []
    state.items.forEach(item => {
      let itemTmp = item
      if (item.id.toString() === payload.id_ticket.toString()) {
        let messages = {}
        if (payload.page === 1) {
          messages = payload
        } else {
          messages = item.messages
          messages.items = [
            ...payload.items,
            ...item.messages.items,
          ]
          messages.pagination = payload.pagination
        }
        itemTmp = {
          ...item,
          messages: messages,
        }
      }
      tmp.push(itemTmp)
    })
    state.items = tmp
  },
  SET_MESSAGE_TO_TICKETS_ITEM(state, payload) {
    let tmp = []
    state.items.forEach(item => {
      let itemTmp = item
      if (item.id.toString() === payload.id_ticket.toString()) {
        let messages = item.messages
        messages.items = [
          ...item.messages.items,
          payload.item,
        ]
        itemTmp = {
          ...item,
          messages: messages,
        }
      }
      tmp.push(itemTmp)
    })
    state.items = tmp
  },
}
