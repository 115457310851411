/**
 * import and init global plugins
 */

import Vue from 'vue'

// plugins
import VueBootstrap from 'bootstrap-vue'
import VueInsProgressBar from 'vue-ins-progress-bar'
import VueEventCalendar from 'vue-event-calendar'
import VueSparkline from 'vue-sparklines'
import * as VueGoogleMaps from 'vue2-google-maps'
import Vueditor from '@agametov/vueditor'
import VueNotification from 'vue-notification'
import VuePanel from '@/plugins/panel/'
import VueDateTimePicker from 'vue-bootstrap-datetimepicker'
import VueSelect from 'vue-select'
import VueDatepicker from 'vuejs-datepicker/dist/vuejs-datepicker.esm.js'
import VueMaskedInput from 'vue-maskedinput'
import VueInputTag from 'vue-input-tag'
import VueSlider from 'vue-slider-component'
import VueGoodTable from 'vue-good-table'
import VueCountdown from '@chenfengyuan/vue-countdown'
import VueColorpicker from 'vue-pop-colorpicker'
import VueCustomScrollbar from 'vue-custom-scrollbar'
import VueApexCharts from 'vue-apexcharts'
import DateRangePicker from 'vue2-daterange-picker'
import { ValidationProvider, ValidationObserver, Validator } from 'vee-validate'
import validationMessages from '@/locale/vee-validate.ru'
import PhoneNumber from 'awesome-phonenumber'
import vClickOutside from 'v-click-outside'
import VueScrollTo from 'vue-scrollto'
import { ObserveVisibility } from 'vue-observe-visibility'
import Multiselect from 'vue-multiselect'
import InfiniteLoading from 'vue-infinite-loading';

// plugins css
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-event-calendar/dist/style.css'
import '@agametov/vueditor/dist/style/vueditor.min.css'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import 'simple-line-icons/css/simple-line-icons.css'
import 'flag-icon-css/css/flag-icon.min.css'
import 'ionicons/dist/css/ionicons.min.css'
import 'vue-good-table/dist/vue-good-table.css'
import 'vue-select/dist/vue-select.css'
import 'vue-slider-component/theme/antd.css'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'

// color admin css
import '@/scss/vue.scss'
import 'bootstrap-social/bootstrap-social.css'

Vue.use(InfiniteLoading,{
  slots: {
    noMore: 'Сообщений больше нет',
  },
});
Vue.use(vClickOutside)
Vue.use(VueBootstrap)
Vue.use(VueEventCalendar, { locale: 'en' })
Vue.use(VueSparkline)
Vue.use(Vueditor)
Vue.use(VueNotification)
Vue.use(VuePanel)
Vue.use(VueDateTimePicker)
Vue.use(VueGoodTable)
Vue.use(VueColorpicker)
Vue.use(VueScrollTo)
Vue.use(VueGoogleMaps, {
  load: {
    key: '',
    libraries: 'places',
  },
})
Vue.use(VueInsProgressBar, {
  position: 'fixed',
  show: true,
  height: '3px',
})
Vue.component('v-select', VueSelect)
Vue.component('multiselect', Multiselect)
Vue.component('datepicker', VueDatepicker)
Vue.component('masked-input', VueMaskedInput)
Vue.component('input-tag', VueInputTag)
Vue.component('vue-slider', VueSlider)
Vue.component('vue-custom-scrollbar', VueCustomScrollbar)
Vue.component('apexchart', VueApexCharts)
Vue.component('date-range-picker', DateRangePicker)
Vue.component(VueCountdown.name, VueCountdown)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.directive('observe-visibility', ObserveVisibility)

const phoneNumber = {
  getMessage: field => {
    return `Поле ${field} имеет неверный формат`
  },
  validate(value) {
    return new Promise(resolve => {
      value = value.indexOf('+7') >= 0 ? value : '+7' + value
      let phone = new PhoneNumber(value)
      resolve({ valid: phone.isValid() })
    })
  },
}
Validator.extend('phone', phoneNumber)

const snils = {
  getMessage: field => {
    return `Поле ${field} имеет неверный формат`
  },
  validate(value) {
    return new Promise(resolve => {
      resolve({ valid: value.match(/^\d{3}-\d{3}-\d{3}-\d{2}$/i) })
    })
  },
}
Validator.extend('snils', snils)

const telegram = {
  getMessage: field => {
    return `Поле ${field} имеет неверный формат`
  },
  validate(value) {
    return new Promise(resolve => {
      resolve({ valid: value.match(/^([a-z0-9_]{5,32})$/i) })
    })
  },
}
Validator.extend('telegram', telegram)

Validator.localize('ru', validationMessages)

/*import VueEcho from 'vue-echo-laravel'
Vue.use(VueEcho, {
  broadcaster: 'pusher',
  key: '2e699a0996d7d37c2514'
});*/
