<template>
	<div>
		<div class="mw-1200px mt-24px">
			<div class="table-responsive-md" v-if="items && items.length">
				<div class="table-outer-radius w-100">
					<table class="table table-bordered mb-0 align-middle w-100">
						<thead>
						<tr>
							<th>№<br/>Заказа</th>
							<th>№<br/>Заявки</th>
							<th v-if="isListener">Название документа</th>
							<th v-if="isMop || isMop || isContactPerson">Тип документа</th>
							<th v-if="isContactPerson">Статус документа</th>
							<th v-if="isContactPerson">Дата обновления</th>
							<th v-if="isMop || isMop || isContactPerson">Шаблон док-та</th>
							<th>Документ</th>
							<th v-if="!isListener">Действия</th>
						</tr>
						</thead>
						<tbody>
						<tr v-for="item in items" :key="`document_${item.id}`">
							<td>{{item.order_id}}</td>
							<td>{{item.bid?.id}}</td>
							<td v-if="isListener">{{item.type_name}}</td>
							<td v-if="isMop || isMop || isContactPerson">{{item.type_name}}</td>
							<td v-if="isContactPerson">{{item.status}}</td>
							<td v-if="isContactPerson">{{item.date_update}}</td>
							<td v-if="isMop || isMop || isContactPerson">
								<a :href="''" :download="''" class="d-inline-flex align-items-center gap-1">
									<IconDocuments :width="20" :height="20" />
									<IconDownload />
								</a>
							</td>
							<td>
								<a :href="''" :download="''" class="d-inline-flex align-items-center gap-1">
									<IconDocuments :width="20" :height="20" />
									<IconDownload />
								</a>
							</td>
							<td v-if="!isListener">
								<div class="d-flex gap-2 align-items-center">

									<DocumentHistoryModal v-if="item.bid" :id-order="item.order_id" :id-bid="item.bid.id" :type-document="item.type_code" :status="item.bid.status">
										<template slot="button">
											<a :href="item.file_link" :download="item.file_name">
												<IconInfo :width="24" :height="24" />
											</a>
										</template>
									</DocumentHistoryModal>
									<RequestDocumentModal :id-order="item.order_id" :id-bid="item.bid.id" :type-document="item.type_code" :status="item.bid.status" v-if="isContactPerson && item.type_code === 'bid_contract' && item.bid">
										<template slot="button">
											<a href="javascript:;" class="btn btn-primary btn-sm px-12px py-8px">Создать запрос</a>
										</template>
									</RequestDocumentModal>
									<AddDocumentModal :id-order="item.order_id" :id-bid="item.bid.id" :type-document="item.type_code" :status="item.bid.status" v-if="item.bid">
										<template slot="button">
											<a href="javascript:void(0)" class="btn btn-success btn-sm px-12px py-8px">Прикрепить документ</a>
										</template>
									</AddDocumentModal>
								</div>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  import AddDocumentModal from '@/components/parts/order/modal/AddDocumentModal'
  import IconDocuments from '@/components/icons/IconDocuments'
  import IconDownload from '@/components/icons/IconDownload'
  import RequestDocumentModal from '@/components/parts/order/modal/RequestDocumentModal'
  import IconInfo from '@/components/icons/IconInfo'
  import DocumentHistoryModal from '@/components/parts/order/modal/DocumentHistoryModal'

  export default {
    name: 'DocumentsList',
    components: { DocumentHistoryModal, IconInfo, RequestDocumentModal, IconDownload, IconDocuments, AddDocumentModal },
    props: ['items'],
    data() {
      return {
	  }
    },
    computed: {
      user() {
        return this.$store.getters['user/user']
      },
      roles() {
        return this.user ?  this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
      },
      isListener() {
        return this.user && this.roles.indexOf('listener') >= 0
      },
      isContactPerson() {
        return this.user && this.roles.indexOf('contact_person') >= 0
      },
      isMop() {
        return this.user && this.roles.indexOf('sales_department_manager') >= 0
      },
      isRop() {
        return this.user && this.roles.indexOf('head_department_manager') >= 0
      },
	},
    created() {
    },
    mounted() {
    },
    methods: {
	},
  }
</script>
