<template>
	<div>
		<!-- BEGIN page-header -->
		<div class="d-flex flex-wrap flex-row align-items-center justify-content-between">
			<h1 class="page-header">Уведомления</h1>
		</div>
		<!-- END page-header -->

		<div :class="`border-radius-lg bg-gray-10 px-16px py-8px mb-12px mw-sm-468px d-flex flex-column ${notification.read_at ? 'opacity-7' : ''}`"
			 v-for="notification in items"
			 :key="`notification_${notification.id}`"
			 v-observe-visibility="visibilityChanged(notification)">
			{{notification.data.message}}
			<router-link :to="getLink(notification)" class="mt-8px text-decoration-underline">{{getText(notification)}}</router-link>
		</div>

		<ul class="pagination mt-4" v-if="pages.length > 1">
			<div class="page-item" :class="page <= 1 ? 'disabled' : ''">
				<router-link :to="'/orders/?page=' + (page - 1)" class="page-link">«</router-link>
			</div>
			<div class="page-item" v-for="item in pages" :key="item + '_page'">
				<router-link :to="'/orders/?page=' + item" class="page-link" :class="page.toString() === item.toString() ? 'active' : ''">{{item}}</router-link>
			</div>
			<div class="page-item" :class="page >= last_page ? 'disabled' : ''">
				<router-link :to="'/orders/?page=' + (page + 1)" class="page-link">»</router-link>
			</div>
		</ul>

		<notifications group="center" position="top center" :duration="10000">
			<template slot="body" slot-scope="props">
				<div :class="'vue-notification-template vue-notification ' + props.item.type">
					<div class="title">
						{{props.item.title}}
					</div>
					<a class="close" @click="props.close">
						<i class="fa fa-times"></i>
					</a>
					<div v-html="props.item.text">
					</div>
				</div>
			</template>
		</notifications>
	</div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'NotificationsList',
    components: {  },

    data() {
      return {
        items: []
      }
    },

    computed: {
      user() {
        return this.$store.getters['user/user']
      },
      roles() {
        return this.user ?  this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
      },
      isListener() {
        return this.user && this.roles.indexOf('listener') >= 0
      },
      isContactPerson() {
        return this.user && this.roles.indexOf('contact_person') >= 0
      },
      isMop() {
        return this.user && this.roles.indexOf('sales_department_manager') >= 0
      },
      isRop() {
        return this.user && this.roles.indexOf('head_department_manager') >= 0
      },
	  notifications() {
        return this.$store.getters['notifications/items']
	  },
      last_page() {
        return this.$store.getters['notifications/last_page']
      },
      pages() {
        let pages = []
        for (let i = 1, l = this.last_page; i <= l; i++) {
          pages.push(i)
        }
        return pages
      },
    },

    watch: {
      $route(to, from) {
        if (to && from && to.query?.page !== from.query?.page) {
          this.page = to.query?.page
          this.getList({ page: this.page })
        }
      },
      notifications(newVal) {
        this.items = newVal
	  }
    },

    mounted() {
      this.page = this.$route.query?.page || 1
      this.getList({ page: this.page })
      this.$store.dispatch('notifications/fetchUnreadNotificationsItems')
    },

    methods: {

      visibilityChanged(notification) {
        if (!notification.read_at) {
          this.items = this.items.map(item => {
            return {
              ...item,
              read_at: item.id === notification.id ? true : item.read_at,
			}
		  })
          axios({
            url: process.env.VUE_APP_API_URL + `/users/notifications/${notification.id}/read`,
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${this.token}`,
            },
            data: {
              value: 1
            }
          })
            .then(res => res.data)
            .then(res => {

            })
		}
	  },

      getLink(notification) {
        if (notification && notification.type) {
          switch (notification.type) {
			case 'App\\Notifications\\TestNewBidInvoice':
			  if (this.isContactPerson) {
                return `/orders/${notification.data.order_id}/bids/${notification.data.id}`
			  } else {
                return `/orders/${notification.data.order_id}`
			  }
          }
          return ''
		}
        return ''
	  },

      getText(notification) {
        if (notification && notification.type) {
          switch (notification.type) {
            case 'App\\Notifications\\TestNewBidInvoice':
              if (this.isContactPerson) {
                return `Перейти к заказу`
              } else {
                return `Перейти к заявке`
              }
          }
          return ''
        }
        return ''
      },

      getList(params) {
        this.loading = true
        this.$store.dispatch('notifications/fetchNotificationsItems', params).then(res => {
          this.loading = false
          if (res.code === 401) {
            Promise.all([
              this.$store.dispatch('orders/reset'),
              this.$store.dispatch('notifications/reset'),
              this.$store.dispatch('documents/resetDocuments'),
              this.$store.dispatch('user/resetUser'),
              this.$store.dispatch('listeners/resetListeners'),
              this.$store.dispatch('bids/reset'),
              this.$store.dispatch('auth/logout')
            ]).then(() => {
              this.$router.push({ path: '/login' })
            })
          }
        })
      },

    },
  }
</script>
