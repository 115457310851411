import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
/*import SecureLS from "secure-ls";*/
import modules from './modules'

Vue.use(Vuex)

/*const ls = new SecureLS({ isCompression: false });*/

const dataState = createPersistedState({
  paths: ['user', 'auth', 'toast'],
 /* storage: {
    getItem: (key) => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: (key) => ls.remove(key),
  },*/
})

export default new Vuex.Store({
  modules,
  strict: process.env.NODE_ENV === 'development',
  plugins: [dataState],
})
