export default {
  itemsBids: state => (idOrder, idBid) => {
    if (state.itemsBids.length) {
      return state.itemsBids.filter(item => Number(idOrder) === Number(item.idOrder) && Number(idBid) === Number(item.idBid))
    }
    return []
  },
  items: state => state.items,
  last_page: state => state.pagination.last_page,
  types: state => state.types,
}
