export default {
  RESET_LISTENERS(state) {
    state = {
      itemsOrder: [],
      items: [],
      pagination: {
        last_page: 1,
      }
    }
  },
  SET_LISTENERS_ITEM(state, payload) {
    let tmp = []
    if (payload.listener) {
      payload = {
        ...payload,
        ...payload.listener,
      }
    }
    if (state.items.length) {
      state.items.forEach(item => {
        if (item.id === payload.id) {
          item = {
            ...item,
            ...payload,
          }
        }
        tmp.push(item)
      })
    } else  {
      tmp.push(payload)
    }
    state.items = tmp
  },
  SET_LISTENERS_DEALS_ITEMS(state, payload) {
    state.deals = payload.items.map(item => ({ ...item, idListener: Number(payload.idListener) }))
    state.pagination.deals.last_page = payload.pagination ? payload.pagination.page_count : 1
  },
  SET_LISTENERS_ORDER_ITEMS(state, payload) {
    state.items = payload.items.map(item => ({ ...item, idOrder: Number(payload.idOrder) }))
  },
  SET_LISTENERS_ITEMS(state, payload) {
    state.items = payload.items.map(item => ({ ...item, idOrder: Number(payload.idOrder) }))
    state.pagination.last_page = payload.pagination ? payload.pagination.page_count : 1
  },
  REMOVE_LISTENER_ORDER_ITEM(state, payload) {
    state.itemsOrder = state.items.filter(item => item.id !== payload)
  },
}
