<template>
	<svg :width="width" :height="height" :style="styleName" :class="className" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_59_11171)">
			<path d="M20 0C14.6969 0 9.60625 2.10844 5.85938 5.85781C2.10863 9.60868 0.00103897 14.6956 0 20C0 25.3022 2.10938 30.3928 5.85938 34.1422C9.60625 37.8916 14.6969 40 20 40C25.3031 40 30.3937 37.8916 34.1406 34.1422C37.8906 30.3928 40 25.3022 40 20C40 14.6978 37.8906 9.60719 34.1406 5.85781C30.3937 2.10844 25.3031 0 20 0Z" fill="url(#paint0_linear_59_11171)"/>
			<path d="M9.0534 19.7891C14.8846 17.2491 18.7721 15.5744 20.7159 14.7653C26.2721 12.455 27.4253 12.0538 28.1784 12.0402C28.344 12.0375 28.7128 12.0784 28.9534 12.273C29.1534 12.437 29.2096 12.6589 29.2378 12.8147C29.2628 12.9703 29.2971 13.325 29.269 13.6019C28.969 16.7644 27.6659 24.4388 27.0034 27.9809C26.7253 29.4797 26.1721 29.9822 25.6378 30.0313C24.4753 30.1381 23.594 29.2638 22.469 28.5266C20.7096 27.3725 19.7159 26.6544 18.0065 25.5284C16.0315 24.2272 17.3128 23.5119 18.4378 22.3431C18.7315 22.0372 23.8503 17.3825 23.9471 16.9603C23.9596 16.9075 23.9721 16.7106 23.8534 16.6069C23.7378 16.5028 23.5659 16.5384 23.4409 16.5666C23.2628 16.6066 20.4534 18.4653 15.0034 22.1425C14.2065 22.6906 13.4846 22.9578 12.8346 22.9438C12.1221 22.9284 10.7471 22.54 9.72527 22.2081C8.47527 21.8009 7.4784 21.5856 7.5659 20.8941C7.60965 20.5341 8.10652 20.1656 9.0534 19.7891Z" :fill="color"/>
		</g>
		<defs>
			<linearGradient id="paint0_linear_59_11171" x1="2000" y1="0" x2="2000" y2="4000" gradientUnits="userSpaceOnUse">
				<stop :stop-color="color3"/>
				<stop offset="1" :stop-color="color2"/>
			</linearGradient>
			<clipPath id="clip0_59_11171">
				<rect width="40" height="40" :fill="color"/>
			</clipPath>
		</defs>
	</svg>

</template>

<script>
  export default {
    name: 'IconSend',
	props: {
      'width': {
		type: Number,
		default: 40,
	  },
	  'height': {
        type: Number,
        default: 40,
	  },
	  'styleName': {
        type: String,
        default: ''
	  },
	  'color': {
        type: String,
        default: '#ffffff'
	  },
      'color2': {
        type: String,
        default: '#229ED9'
      },
      'color3': {
        type: String,
        default: '#2AABEE'
      },
      'className': {
        type: String,
        default: ''
      },
    },
    data() {
      return {}
    },
  }
</script>
