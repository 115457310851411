<template>
	<div>
		<div class="mw-1200px">
			<div class="table-responsive-md w-100" v-if="items && items.length">
				<div class="table-outer-radius w-100">
					<table class="table table-bordered mb-0 align-middle w-100">
						<thead>
						<tr>
							<th>№</th>
							<th>СНИЛС</th>
							<th>Серия и номер паспорта</th>
							<th>Гражданство</th>
							<th>Фамилия</th>
							<th>Имя</th>
							<th>Отчество</th>
							<th>№ Заказа</th>
							<th>№ Заявки</th>
							<!--<th class="w-50px">Экспорт в CSV</th>-->
							<!--<th class="w-50px"></th>-->
							<th class="w-100px"></th>
						</tr>
						</thead>
						<tbody>
						<tr v-for="item in items" :key="`listener_${item.id}`" v-if="item">
							<td>{{item.id}}</td>
							<td>{{item.snils.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/g, `$1-$2-$3-$4`)}}</td>
							<td>{{item.passport_series_number}}</td>
							<td>{{item.citizenship_formatted}}</td>
							<td>{{item.surname}}</td>
							<td>{{item.name}}</td>
							<td>{{item.patronymic}}</td>
							<td>{{item.order_id}}</td>
							<td>{{item.bid_id}}</td>
							<!--<td>
								<div class="form-check d-flex align-items-center justify-content-center my-4px">
									<input type="checkbox"
										   v-model="item.checked"
										   :id="`table_checkbox_${item?.id}`"
										   class="form-check-input form-check-input-bordered">
								</div>
							</td>-->
							<!--<td>
								<div class="d-flex flex-column align-items-center gap-2">
									<router-link :to="``">
										<IconInfo :width="24" :height="24" />
									</router-link>
									<a href="#">
										<IconChat />
									</a>
								</div>
							</td>-->
							<td class="px-8px pt-8px pb-12px ">
								<div class="d-flex flex-column align-items-center gap-2">
									<b-dropdown toggle-class="p-0" variant="link" right>
										<template #button-content>
											<a href="javascript:;" class="btn btn-primary btn-sm px-12px py-8px">Отправить сообщение</a>
										</template>
										<a href="javascript:;" class="dropdown-item py-8px lh-14">Начать чат</a>
										<a href="javascript:;" class="dropdown-item py-8px lh-14" @click="sendAccesses(item.id)">Отправить доступы</a>
										<a href="javascript:;" class="dropdown-item py-8px lh-14" @click="sendRequest(item.id)">Отправить запрос<br/>на внесение изменений в ЛД</a>
									</b-dropdown>
									<router-link :to="`/listeners/${item.id}`" class="text-decoration-underline">Личное дело</router-link>
									<router-link :to="`/listeners/${item.id}/deals`" class="text-decoration-underline">Сделки</router-link>
									<!--<router-link :to="``" class="text-decoration-underline">История действий</router-link>-->
								</div>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  import IconInfo from '@/components/icons/IconInfo'
  import IconChat from '@/components/icons/IconChat'
  import axios from 'axios'
  export default {
    name: 'DBListenersList',
    components: { IconChat, IconInfo },
    props: ['items'],
    data() {
      return {}
    },
    computed: {
      user() {
        return this.$store.getters['user/user']
      },
      roles() {
        return this.user ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
      },
	},
    created() {
    },
    mounted() {
    },
    methods: {
      sendAccesses( listener_id) {
        if (!listener_id) return

        let path = ''
        let roles = this.user.roles ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
        roles.forEach(role => {
          switch (role) {
            case 'sales_department_manager':
              path = `/mop/listeners/${listener_id}/send_access`
              break
            case 'head_department_manager':
              path = `/rop/listeners/${listener_id}/send_access`
              break
          }
        })

        axios({
          url: process.env.VUE_APP_API_URL + path,
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.token}`,
          },
        })
          .then(res => res.data)
          .then(res => {
            if (res.message && !res.success) {
              this.$notify({
                group: 'center',
                title: ``,
                text: res.message,
                type: 'error',
              })
            } else {
              this.$notify({
                group: 'center',
                title: ``,
                text: `Отправлено`,
                type: 'success',
              })
            }
          })
      },
      sendRequest(listener_id) {
        if (!listener_id) return

        let path = ''
        let roles = this.user.roles ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
        roles.forEach(role => {
          switch (role) {
            case 'sales_department_manager':
              path = `/mop/listeners/${listener_id}/send_request_change_pm`
              break
            case 'head_department_manager':
              path = `/rop/listeners/${listener_id}/send_request_change_pm`
              break
          }
        })

        axios({
          url: process.env.VUE_APP_API_URL + path,
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.token}`,
          },
        })
          .then(res => res.data)
          .then(res => {
            if (res.message && !res.success) {
              this.$notify({
                group: 'center',
                title: ``,
                text: res.message,
                type: 'error',
              })
            } else {
              this.$notify({
                group: 'center',
                title: ``,
                text: `Отправлено`,
                type: 'success',
              })
            }
          })
      },
    },
  }
</script>
