<template>
	<div>
		<div class="mw-1200px mt-25px">
			<div class="table-responsive-md" v-if="items && items.length">
				<div class="table-outer-radius w-100">
					<table class="table table-bordered mb-0 align-middle w-100">
						<thead>
						<tr>
							<th>№ Заказа</th>
							<th>№ Заявки</th>
							<th>Номер обращения</th>
							<th>Тема</th>
							<th>Статус</th>
							<th>Обновлено</th>
							<th class="w-100px"></th>
						</tr>
						</thead>
						<tbody>
						<tr v-for="item in items" :key="`ticket_${item.id}`">
							<td>{{item.order_id}}</td>
							<td>{{item.bid_id}}</td>
							<td>{{item.id}}</td>
							<td>{{item.theme}}</td>
							<td>{{item.status?.name}}</td>
							<td>{{item.updated_at}}</td>
							<td>
								<router-link :to="`/tickets/${item.id}`" class="btn btn-primary btn-sm px-12px py-8px">Перейти</router-link>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  export default {
    name: 'TicketsList',
    props: ['items'],
    data() {
      return {}
    },
    computed: {
      user() {
        return this.$store.getters['user/user']
      },
      roles() {
        return this.user ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
      },
      isListener() {
        return this.user && this.roles.indexOf('listener') >= 0
      },
      isContactPerson() {
        return this.user && this.roles.indexOf('contact_person') >= 0
      },
      isMop() {
        return this.user && this.roles.indexOf('sales_department_manager') >= 0
      },
      isRop() {
        return this.user && this.roles.indexOf('head_department_manager') >= 0
      },
    },
    created() {
    },
    mounted() {
    },
    methods: {},
  }
</script>
