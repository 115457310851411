<template>
	<div>
	</div>
</template>

<script>
  export default {
    name: 'home',
    data() {
      return {}
    },
    computed: {
      user() {
        return this.$store.getters['user/user']
      },
      roles() {
        return this.user ?  this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
      },
    },
    mounted() {
      if (this.roles.length) {
        if (this.roles.indexOf('listener') >= 0) {
          if (this.user.need_edit_profile && this.user.need_edit_personal_matter_documents && !this.user.need_send_bid_confirm) {
            this.$router.replace({ name: 'Profile' })
          } else if (this.user.need_edit_personal_matter_documents && !this.user.need_edit_profile && !this.user.need_send_bid_confirm) {
            this.$router.replace({ name: 'Profile', hash: '#documents' })
          } else {
            this.$router.replace({ name: 'EducationBids' })
          }
		} else {
          this.$router.replace({ name: 'OrdersList' })
		}
      }
    },
    methods: {},
  }
</script>
