import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  linkActiveClass: 'is-active',
  mode: 'history',
  routes,
})

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.getters['auth/token']

  if (to.name !== 'UserLogin' && to.name !== 'UserForgotPassword' && !isAuthenticated) {
    if (from.name !== 'UserLogin') {
      next({ name: 'UserLogin' })
    }
  } else next()

  if (isAuthenticated && to.name !== 'UserLogin' && to.name !== 'UserForgotPassword') {
    const request = await store.dispatch('user/fetchGetUser')
    const response = await request
    if (response.code && response.code === 401) {
      Promise.all([
        store.dispatch('orders/reset'),
        store.dispatch('user/resetUser'),
        store.dispatch('listeners/resetListeners'),
        store.dispatch('bids/reset'),
        store.dispatch('auth/logout')
      ]).then(res => {
        next({ name: 'UserLogin' })
      })
    }
    if (response.data) {
      store.dispatch('notifications/fetchUnreadNotificationsItems')
    } else next(to.fullPath)
  } else next()
})

export default router
