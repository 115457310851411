import state from './ticketsState'
import mutations from './ticketsMutations'
import getters from './ticketsGetters'
import actions from './ticketsActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
