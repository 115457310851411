<template>
	<!-- begin sidebar nav -->
	<div class="menu" v-if="menus">
		<div class="menu-search mb-n3" v-if="appOptions.appSidebarSearch">
			<input type="text" v-on:keyup="handleSidebarFilter" class="form-control" placeholder="Sidebar menu filter..." />
		</div>
		<!--<div class="menu-header">Навигация</div>-->
		<template v-for="menu in menus">
			<sidebar-nav-list
					ref="sidebarNavList"
					v-bind:menu="menu"
					v-bind:scrollTop="scrollTop"
					v-bind:key="menu.path"
					v-bind:status="menu.status"
					v-on:collapse-other="handleCollapseOther(menu)"
					v-on:show-float-submenu="handleShowFloatSubmenu"
					v-on:hide-float-submenu="handleHideFloatSubmenu"></sidebar-nav-list>
		</template>

		<!-- BEGIN minify-button -->
		<div class="menu-item d-flex">
			<a href="javascript:;" class="app-sidebar-minify-btn ms-auto" v-on:click="handleSidebarMinify()"><i class="fa fa-angle-double-left"></i></a>
		</div>
		<!-- END minify-button -->
	</div>
	<!-- end sidebar nav -->
</template>

<script>
  import SidebarNavList from './SidebarNavList.vue'
  import AppOptions from '@/config/options.js'
  import { mapGetters } from 'vuex'

  export default {
    name: 'SidebarNav',
    props: ['scrollTop'],
    components: {
      SidebarNavList,
    },
    data() {
      return {
        appOptions: AppOptions,
      }
    },
    created() {
      this.getList()
    },
    computed: {
      user() {
        return this.$store.getters['user/user']
      },
      ordersCount() {
        return this.$store.getters['orders/items_count']
      },
      roles() {
        return this.user ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
      },
      unread() {
        return this.$store.getters['notifications/unread']
      },
      menus() {
        let menu = []

        if (this.user) {
          let roles = this.user.roles ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
          roles.forEach(role => {
            switch (role) {
              case 'listener':
                menu = [
                  ...menu,
                  { path: '/profile', title: 'Профиль' },
                  {
                    path: '/education', title: 'Образование', children: [
                      { path: '/education/bids', title: 'Неподтвержденные заявки' },
                      { path: '/education/programs', title: 'Программы обучения' },
                      { path: '/education/documents', title: 'Документы по образовательному процессу' },
                    ],
                  },
                  { path: '/notifications', title: 'Уведомления', badge: this.unread },
                ]
                break
              case 'contact_person':
                menu = [
                  ...menu,
                  { path: '/orders', title: 'Заказы', badge: this.ordersCount || '' },
                  { path: '/documents', title: 'Бухгалтерские документы' },
                  { path: '/tickets', title: 'Обращения' },
                  { path: '/notifications', title: 'Уведомления', badge: this.unread },
                ]
                break
              case 'sales_department_manager':
                menu = [
                  ...menu,
                  { path: '/orders', title: 'Заказы', badge: this.ordersCount || '' },
                  { path: '/listeners', title: 'База данных слушатели' },
                  { path: '/documents', title: 'Документооборот' },
                  { path: '/tickets', title: 'Обращения' },
                  { path: '/notifications', title: 'Уведомления', badge: this.unread },
                ]
				break;
              case 'head_department_manager':
              default:
                menu = [
                  ...menu,
                  { path: '/orders', title: 'Заказы', badge: this.ordersCount || '' },
                  { path: '/documents', title: 'Документооборот' },
                  { path: '/tickets', title: 'Обращения' },
                  { path: '/notifications', title: 'Уведомления', badge: this.unread },
                ]
                break
            }
          })
        }

        if (process.env.NODE_ENV === 'development') {
          menu = [
            ...menu,
            {
              path: '/dashboard', icon: 'fa fa-th', title: 'Dashboard',
              children: [
                { path: '/dashboard/v1', title: 'Dashboard v1' },
                { path: '/dashboard/v2', title: 'Dashboard v2' },
                { path: '/dashboard/v3', title: 'Dashboard v3' },
              ],
            },
            {
              path: '/email', icon: 'fa fa-hdd', title: 'Email', badge: '10',
              children: [
                { path: '/email/inbox', title: 'Inbox' },
                { path: '/email/compose', title: 'Compose' },
                { path: '/email/detail', title: 'Detail' },
              ],
            },
            { path: '/widgets', icon: 'fab fa-simplybuilt', title: 'Widgets', label: 'NEW' },
            {
              path: '/ui', icon: 'fa fa-gem', title: 'UI Elements', label: 'NEW',
              children: [
                { path: '/ui/general', title: 'General', highlight: true },
                { path: '/ui/typography', title: 'Typograhy' },
                { path: '/ui/tabs-accordions', title: 'Tabs & Accordions' },
                { path: '/ui/modal-notification', title: 'Modal & Notification' },
                { path: '/ui/widget-boxes', title: 'Widget Boxes' },
                { path: '/ui/media-object', title: 'Media Object' },
                { path: '/ui/buttons', title: 'Buttons', highlight: true },
                { path: '/ui/icons', title: 'Icons' },
                { path: '/ui/simple-line-icons', title: 'Simple Line Icons' },
                { path: '/ui/ionicons', title: 'Ionicons' },
                { path: '/ui/language-bar-icon', title: 'Language Bar & Icon' },
                { path: '/ui/social-buttons', title: 'Social Buttons' },
              ],
            },
            { path: '/bootstrap-5', img: '/assets/img/logo/logo-bs5.png', title: 'Bootstrap 5', label: 'NEW' },
            {
              path: '/form', icon: 'fa fa-list-ol', title: 'Form Stuff', label: 'NEW',
              children: [
                { path: '/form/elements', title: 'Form Elements', highlight: true },
                { path: '/form/plugins', title: 'Form Plugins', highlight: true },
                { path: '/form/wizards', title: 'Form Wizards', highlight: true },
              ],
            },
            {
              path: '/table', icon: 'fa fa-table', title: 'Tables',
              children: [
                { path: '/table/basic', title: 'Basic Tables' },
                { path: '/table/data', title: 'Data Tables' },
              ],
            },
            {
              path: '/pos', icon: 'fa fa-cash-register', title: 'POS System', label: 'NEW',
              children: [
                { path: '/pos/customer-order', title: 'Customer Order' },
                { path: '/pos/counter-checkout', title: 'Counter Checkout' },
                { path: '/pos/kitchen-order', title: 'Kitchen Order' },
                { path: '/pos/table-booking', title: 'Table Booking' },
                { path: '/pos/menu-stock', title: 'Menu Stock' },
              ],
            },
            {
              path: '/frontend', icon: 'fa fa-star', title: 'FrontEnd',
              children: [
                { path: '/frontend/one-page-parallax', title: 'One Page Parallax' },
                { path: '/frontend/blog', title: 'Blog' },
                { path: '/frontend/forum', title: 'Forum' },
                { path: '/frontend/e-commerce', title: 'E-Commerce' },
              ],
            },
            {
              path: '/chart', icon: 'fa fa-chart-pie', title: 'Chart', label: 'NEW',
              children: [
                { path: '/chart/js', title: 'Chart JS' },
                { path: '/chart/d3', title: 'd3 Chart' },
                { path: '/chart/apex', title: 'Apex Chart' },
              ],
            },
            { path: '/calendar', icon: 'fa fa-calendar', title: 'Calendar' },
            { path: '/map', icon: 'fa fa-map', title: 'Map' },
            { path: '/gallery', icon: 'fa fa-image', title: 'Gallery' },
            {
              path: '/page-option', icon: 'fa fa-cogs', title: 'Page Options', label: 'NEW',
              children: [
                { path: '/page-option/blank', title: 'Blank Page' },
                { path: '/page-option/with-footer', title: 'Page with Footer' },
                { path: '/page-option/with-fixed-footer', title: 'Page with Fixed Footer', highlight: true },
                { path: '/page-option/without-sidebar', title: 'Page without Sidebar' },
                { path: '/page-option/with-right-sidebar', title: 'Page with Right Sidebar' },
                { path: '/page-option/with-minified-sidebar', title: 'Page with Minified Sidebar' },
                { path: '/page-option/with-two-sidebar', title: 'Page with Two Sidebar' },
                { path: '/page-option/full-height', title: 'Full Height Content' },
                { path: '/page-option/with-wide-sidebar', title: 'Page with Wide Sidebar' },
                { path: '/page-option/with-light-sidebar', title: 'Page with Light Sidebar' },
                { path: '/page-option/with-mega-menu', title: 'Page with Mega Menu' },
                { path: '/page-option/with-top-menu', title: 'Page with Top Menu' },
                { path: '/page-option/with-boxed-layout', title: 'Page with Boxed Layout' },
                { path: '/page-option/with-mixed-menu', title: 'Page with Mixed Menu' },
                { path: '/page-option/boxed-layout-with-mixed-menu', title: 'Boxed Layout with Mixed Menu' },
                { path: '/page-option/with-transparent-sidebar', title: 'Page with Transparent Sidebar' },
                { path: '/page-option/with-search-sidebar', title: 'Page with Search Sidebar', highlight: true },
              ],
            },
            {
              path: '/extra', icon: 'fa fa-gift', title: 'Extra', label: 'NEW',
              children: [
                { path: '/extra/timeline', title: 'Timeline' },
                { path: '/extra/coming-soon', title: 'Coming Soon Page' },
                { path: '/extra/search', title: 'Search Results' },
                { path: '/extra/invoice', title: 'Invoice' },
                { path: '/extra/error', title: '404 Error Page' },
                { path: '/extra/profile', title: 'Profile Page' },
                { path: '/extra/scrum-board', title: 'Scrum Board', highlight: true },
                { path: '/extra/cookie-acceptance-banner', title: 'Cookie Acceptance Banner', highlight: true },
              ],
            },
            {
              path: '/user', icon: 'fa fa-key', title: 'Login & Register',
              children: [
                { path: '/user/login-v1', title: 'Login' },
                { path: '/user/login-v2', title: 'Login v2' },
                { path: '/user/login-v3', title: 'Login v3' },
                { path: '/user/register-v3', title: 'Register v3' },
              ],
            },
            {
              path: '/version', icon: 'fa fa-cubes', title: 'Version', label: 'NEW',
              children: [
                { path: '/version/html', title: 'HTML' },
                { path: '/version/ajax', title: 'AJAX' },
                { path: '/version/angularjs', title: 'ANGULAR JS' },
                { path: '/version/angularjs8', title: 'ANGULAR JS 8' },
                { path: '/version/laravel', title: 'LARAVEL' },
                { path: '/version/material', title: 'MATERIAL DESIGN' },
                { path: '/version/apple', title: 'APPLE DESIGN' },
                { path: '/version/transparent', title: 'TRANSPARENT DESIGN', highlight: true },
                { path: '/version/facebook', title: 'FACEBOOK DESIGN', highlight: true },
                { path: '/version/google', title: 'GOOGLE DESIGN', highlight: true },
              ],
            },
            {
              path: '/helper', icon: 'fa fa-medkit', title: 'Helper',
              children: [
                { path: '/helper/css', title: 'Predefined CSS Classes' },
              ],
            },
            {
              path: '/menu', icon: 'fa fa-align-left', title: 'Menu Level',
              children: [
                {
                  path: '/menu/menu-1-1', title: 'Menu 1.1',
                  children: [
                    {
                      path: '/menu/menu-1-1/menu-2-1', title: 'Menu 2.1',
                      children: [
                        { path: '/menu/menu-1-1/menu-2-1/menu-3-1', title: 'Menu 3.1' },
                        { path: '/menu/menu-1-1/menu-2-1/menu-3-2', title: 'Menu 3.2' },
                      ],
                    },
                    { path: '/menu/menu-1-1/menu-2-2', title: 'Menu 2.2' },
                    { path: '/menu/menu-1-1/menu-2-3', title: 'Menu 2.3' },
                  ],
                },
                { path: '/menu/menu-1-2', title: 'Menu 1.2' },
                { path: '/menu/menu-1-3', title: 'Menu 1.3' },
              ],
            },
          ]
        }
        return menu
      },
    },
    methods: {
      getList() {
        this.$store.dispatch('orders/fetchOrderItems', this.page)
      },
      handleShowFloatSubmenu: function (menu, offset) {
        this.$emit('show-float-submenu', menu, offset)
      },
      handleHideFloatSubmenu: function () {
        this.$emit('hide-float-submenu')
      },
      handleCollapseOther: function (menu) {
        for (var i = 0; i < this.menus.length; i++) {
          this.$refs.sidebarNavList[i].collapse(menu)
        }
      },
      handleSidebarMinify: function () {
        this.appOptions.appSidebarMinified = !this.appOptions.appSidebarMinified
      },
      handleSidebarFilter: function (e) {
        var value = e.target.value
        value = value.toLowerCase()

        if (value) {
          for (var x = 0; x < this.menus.length; x++) {
            var title = (this.menus[x].title).toLowerCase()
            var children = this.menus[x].children

            if (title.search(value) > -1) {
              this.$refs.sidebarNavList[x].show()

              if (children) {
                this.$refs.sidebarNavList[x].searchExpand()
              }
            } else {
              if (children) {
                var hasActive = false
                for (var y = 0; y < children.length; y++) {
                  var title2 = (children[y].title).toLowerCase()

                  if (title2.search(value) > -1) {
                    hasActive = true
                    this.$refs.sidebarNavList[x].$refs.sidebarNavList[y].show()
                    this.$refs.sidebarNavList[x].searchExpand()
                  } else {
                    if (hasActive) {
                      this.$refs.sidebarNavList[x].searchExpand()
                    } else {
                      this.$refs.sidebarNavList[x].hide()
                    }
                    this.$refs.sidebarNavList[x].$refs.sidebarNavList[y].hide()
                  }
                }
              } else {
                this.$refs.sidebarNavList[x].hide()
              }
            }
          }
        } else {
          for (var a = 0; a < this.menus.length; a++) {
            this.$refs.sidebarNavList[a].show()

            var submenu = this.menus[a].children
            if (submenu) {
              for (var b = 0; b < submenu.length; b++) {
                this.$refs.sidebarNavList[a].$refs.sidebarNavList[b].show()
              }
            }
          }
        }
        console.log('------')
      },
    },
  }
</script>
