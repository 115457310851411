<template>
	<div>
		<div class="mb-25px">
			<router-link to="/tickets/" class="d-inline-flex align-items-center text-decoration-none text-dark">
				<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-10px">
					<path d="M6.79608 10.3333C6.72165 10.4043 6.66239 10.4896 6.62191 10.5841C6.58142 10.6787 6.56055 10.7804 6.56055 10.8833C6.56055 10.9861 6.58142 11.0879 6.62191 11.1824C6.66239 11.277 6.72165 11.3623 6.79608 11.4333L14.6528 19.29L15.7528 18.19L8.44608 10.8833L15.7528 3.57562L14.6528 2.47562L6.79608 10.3333Z" fill="#2B2A29" />
				</svg>
				Назад
			</router-link>
		</div>
		<!-- BEGIN page-header -->
		<div class="d-flex flex-wrap flex-row align-items-end gap-4 mb-25px" v-if="ticket">
			<h1 class="page-header mb-0">Обращение №{{ticket.id}}</h1>
		</div>
		<!-- END page-header -->
		<div class="mw-1200px" v-if="ticket">
			<div class="d-flex flex-wrap align-items-start flex-row-reverse justify-content-end">
				<div class="border border-2 border-radius-lg w-100 w-md-300px px-24px pt-24px pb-16px ms-md-4 d-flex flex-column gap-3">
					<div>
						<div class="text-gray-600 fs-12px mb-3px">Статус</div>
						{{ticket.status?.name}}
					</div>
					<div>
						<div class="text-gray-600 fs-12px mb-3px">Отдел</div>
						{{ticket.department?.name}}
					</div>
					<div>
						<div class="text-gray-600 fs-12px mb-3px">Тема</div>
						{{ticket.theme}}
					</div>
					<div>
						<div class="text-gray-600 fs-12px mb-3px">Обновлено</div>
						{{ticket.updated_at}}
					</div>
				</div>
				<!-- begin widget-chat -->
				<div class="widget-chat border border-2 border-radius-lg w-100 w-md-600px px-24px pt-24px pb-16px">
					<!-- begin widget-chat-body -->

					<div class="widget-chat-body overflow-y-auto" ref="messages">
						<infinite-loading direction="top" @infinite="getList"></infinite-loading>
						<template v-if="ticket.messages && ticket.messages.items">
							<div :class="`widget-chat-item with-media ${user.id === message.author.id ? 'end' : 'start'}`" v-for="message in ticket.messages.items" :key="`ticket_${message.id}`">
								<div class="widget-chat-media">
									{{message.author.surname[0]}}{{message.author.name[0]}}
								</div>
								<div class="widget-chat-info">
									<div class="widget-chat-name mb-8px">{{[message.author.surname, message.author.name, message.author.patronymic].join(' ')}}</div>
									<div class="widget-chat-info-container">
										<div class="widget-chat-message">{{message.message}}</div>
										<div class="widget-chat-files d-flex flex-wrap gap-2 mt-8px" v-if="message.files">
											<a :href="file.link" v-for="file in message.files" :key="`message_${message.id}_file_${file.id}`">{{file.name}}</a>
										</div>
										<div class="widget-chat-time">{{message.created_at}}</div>
									</div>
								</div>
							</div>
						</template>
					</div>
					<!-- end widget-chat-body -->

					<!-- begin widget-input -->
					<div class="widget-input">
						<form method="POST" @submit.prevent="sendMessage">
							<div class="widget-input-container">
								<div class="widget-input-box">
									<input type="text" class="form-control" placeholder="Сообщение..." v-model="message" />
								</div>
								<div class="widget-input-icon p-0">
									<button type="submit" href="#" class="p-0 ms-12px bg-white border-0">
										<IconSend />
									</button>
								</div>
								<div class="widget-input-icon p-0"><a href="#" class="p-0 ms-12px">
									<IconFile />
								</a></div>
							</div>
						</form>
					</div>
					<!-- end widget-input -->
				</div>
				<!-- end widget-chat -->
			</div>
		</div>

		<notifications group="center" position="top center" :duration="10000">
			<template slot="body" slot-scope="props">
				<div :class="'vue-notification-template vue-notification ' + props.item.type">
					<div class="title">
						{{props.item.title}}
					</div>
					<a class="close" @click="props.close">
						<i class="fa fa-times"></i>
					</a>
					<div v-html="props.item.text">
					</div>
				</div>
			</template>
		</notifications>
	</div>
</template>

<script>


  import IconSend from '@/components/icons/IconSend'
  import IconFile from '@/components/icons/IconFile'

  export default {
    name: 'TicketsDetail',
    components: { IconFile, IconSend },
    data() {
      return {
        page: 1,
        loaded: true,
        message: '',
      }
    },
    computed: {
      user() {
        return this.$store.getters['user/user']
      },
      idTicket() {
        return this.$route.params.id
      },
      ticket() {
        return this.idTicket ? this.$store.getters['tickets/itemById'](this.idTicket) : null
      },
      last_page() {
        return this.ticket && this.ticket.messages ? this.ticket.messages.pagination.page_count : 1
      },
      busy() {
        return this.last_page === this.page
      },
    },

    watch: {},

    mounted() {
      this.$store.dispatch('tickets/fetchTicketsItem', this.idTicket)
        .then((res) => {
          this.loaded = false
          if (res && res.code === 401) {
            Promise.all([
              this.$store.dispatch('orders/reset'),
              this.$store.dispatch('notifications/reset'),
              this.$store.dispatch('documents/resetDocuments'),
              this.$store.dispatch('user/resetUser'),
              this.$store.dispatch('listeners/resetListeners'),
              this.$store.dispatch('bids/reset'),
              this.$store.dispatch('auth/logout'),
            ]).then(() => {
              this.$router.push({ path: '/login' })
            })
          }
        })
    },
    methods: {
      getList($state) {
        if (this.loaded) return false
        console.log('trigger event')
        if (this.page > this.last_page) return false
        this.$store.dispatch('tickets/fetchTicketsItemMessages', { page: this.page, id: this.idTicket })
          .then(res => {
            if (this.last_page > this.page) {
              this.page += 1
              $state.loaded()
            } else {
              $state.complete()
            }
          })
      },
      sendMessage() {
        if (!this.message) return false

        this.$store.dispatch('tickets/fetchTicketsItemSendMessage', { id: this.idTicket, message: this.message })
          .then(() => {
            this.message = ''
            this.$refs['messages'].scrollTop = this.$refs['messages'].scrollHeight
          })
      },
    },
  }
</script>

<style>
	.widget-chat .widget-chat-body {
		height: calc(100vh - 350px);
		min-height: 300px;
	}

	.widget-chat .widget-chat-body::-webkit-scrollbar {
		width: 6px;
	}

	.widget-chat .widget-chat-body::-webkit-scrollbar-track {
		background-color: #ffffff;
	}

	.widget-chat .widget-chat-body::-webkit-scrollbar-thumb {
		background-color: #aaa;
		border-radius: 6px;
	}
</style>
