<template>
	<svg :width="width" :height="height" :style="styleName" :class="className" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M6.44226 2H17.5623C20.0954 2.08694 22.0809 4.2066 22.0023 6.74V13.26C22.0399 14.4835 21.5883 15.6716 20.7475 16.5612C19.9067 17.4508 18.746 17.9686 17.5223 18H8.00226C7.34345 18.0136 6.71868 18.2953 6.27226 18.78L3.90226 21.31C3.70147 21.529 3.41933 21.6556 3.12226 21.66C2.81544 21.6522 2.52447 21.522 2.31412 21.2985C2.10377 21.075 1.99148 20.7767 2.00226 20.47V6.74C1.92363 4.2066 3.90913 2.08694 6.44226 2ZM17.5623 16.5C19.2672 16.4137 20.5815 14.9653 20.5023 13.26V6.74C20.5815 5.03472 19.2672 3.58627 17.5623 3.5H6.44225C4.73731 3.58627 3.42298 5.03472 3.50225 6.74V19.54L5.13225 17.76C5.87768 16.9671 6.91401 16.5121 8.00225 16.5H17.5623Z" :fill="color"/>
		<path d="M8.25225 8.75H13.2523C13.6665 8.75 14.0023 8.41421 14.0023 8C14.0023 7.58579 13.6665 7.25 13.2523 7.25H8.25225C7.83804 7.25 7.50225 7.58579 7.50225 8C7.50225 8.41421 7.83804 8.75 8.25225 8.75Z" :fill="color"/>
		<path d="M15.7523 11.25H8.25225C7.83804 11.25 7.50225 11.5858 7.50225 12C7.50225 12.4142 7.83804 12.75 8.25225 12.75H15.7523C16.1665 12.75 16.5023 12.4142 16.5023 12C16.5023 11.5858 16.1665 11.25 15.7523 11.25Z" :fill="color"/>
	</svg>

</template>

<script>
  export default {
    name: 'IconChat',
	props: {
      'width': {
		type: Number,
		default: 24,
	  },
	  'height': {
        type: Number,
        default: 24,
	  },
	  'styleName': {
        type: String,
        default: ''
	  },
	  'color': {
        type: String,
        default: '#2B2A29'
	  },
      'className': {
        type: String,
        default: ''
      },
    },
    data() {
      return {}
    },
  }
</script>
