<template>
	<div>
		<!-- BEGIN page-header -->
		<div class="d-flex flex-wrap flex-row align-items-end gap-4 mb-25px">
			<h1 class="page-header mb-0">Программы обучения</h1>
		</div>
		<!-- END page-header -->
		<div class="mw-1200px">
			<EducationBidConfirmedCard v-for="bid in bids" v-if="bids.length" :key="`education_card_${bid.id}`" :bid="bid" />

			<div class="pagination mt-4" v-if="pages.length > 1">
				<div class="page-item" :class="page <= 1 ? 'disabled' : ''">
					<router-link :to="'/orders/?page=' + (page - 1)" class="page-link">«</router-link>
				</div>
				<div class="page-item" v-for="item in pages" :key="item + '_page'">
					<router-link :to="'/orders/?page=' + item" class="page-link" :class="page.toString() === item.toString() ? 'active' : ''">{{item}}</router-link>
				</div>
				<div class="page-item" :class="page >= last_page ? 'disabled' : ''">
					<router-link :to="'/orders/?page=' + (page + 1)" class="page-link">»</router-link>
				</div>
			</div>
		</div>

		<notifications group="center" position="top center" :duration="10000">
			<template slot="body" slot-scope="props">
				<div :class="'vue-notification-template vue-notification ' + props.item.type">
					<div class="title">
						{{props.item.title}}
					</div>
					<a class="close" @click="props.close">
						<i class="fa fa-times"></i>
					</a>
					<div v-html="props.item.text">
					</div>
				</div>
			</template>
		</notifications>
	</div>
</template>

<script>

  import EducationBidConfirmedCard from '@/components/parts/education/EducationBidConfirmedCard'

  export default {
    name: 'EducationBidsConfirmed',
    components: { EducationBidConfirmedCard },
    data() {
      return {
        page: 1,
	  }
    },
    computed: {
      user() {
        return this.$store.getters['user/user']
      },
      bids() {
        return this.$store.getters['bids/items_confirmed']
      },
      last_page() {
        return this.$store.getters['bids/last_page']
      },
      pages() {
        let pages = []
        for (let i = 1, l = this.last_page; i <= l; i++) {
          pages.push(i)
        }
        return pages
      },
    },

    watch: {
      $route(to, from) {
        if (to && from && to.query?.page !== from.query?.page) {
          this.page = to.query?.page
          this.getList({ page: this.page })
        }
      },
    },

    mounted() {
      if (this.user.need_edit_profile || this.user.need_edit_personal_matter_documents || this.user.need_send_bid_confirm) {

        this.$router.replace({ name: 'EducationBids' })
	  }

      this.page = this.$route.query?.page || 1
      this.getList({ page: this.page })
    },
    methods: {

      getList(params) {
        this.loading = true
        this.$store.dispatch('bids/fetchBidConfirmedItems', params).then(res => {
          this.loading = false
          if (res.code === 401) {
            Promise.all([
              this.$store.dispatch('orders/reset'),
              this.$store.dispatch('notifications/reset'),
              this.$store.dispatch('documents/resetDocuments'),
              this.$store.dispatch('user/resetUser'),
              this.$store.dispatch('listeners/resetListeners'),
              this.$store.dispatch('bids/reset'),
              this.$store.dispatch('auth/logout')
            ]).then(() => {
              this.$router.push({ path: '/login' })
            })
          }
        })
      },

	},
  }
</script>
