<template>
	<svg :width="width" :height="height" :style="styleName" :class="className" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M1.92871 18.5723H19.072V17.1439H1.92871V18.5723ZM4.92871 9.7531L10.0004 14.7889C10.0649 14.8566 10.1425 14.9105 10.2284 14.9473C10.3144 14.9841 10.4069 15.0031 10.5004 15.0031C10.5939 15.0031 10.6864 14.9841 10.7723 14.9473C10.8583 14.9105 10.9359 14.8566 11.0004 14.7889L16.0362 9.7531L15.0362 8.7531L11.2145 12.5739V1.43143H9.78621V12.5739L5.92871 8.7531L4.92871 9.7531Z" :fill="color"/>
	</svg>
</template>

<script>
  export default {
    name: 'IconDownload',
	props: {
      'width': {
		type: Number,
		default: 21,
	  },
	  'height': {
        type: Number,
        default: 21,
	  },
	  'styleName': {
        type: String,
        default: ''
	  },
	  'color': {
        type: String,
        default: '#00B9E4'
	  },
      'className': {
        type: String,
        default: ''
      },
    },
    data() {
      return {}
    },
  }
</script>
