export default {
  RESET_ORDERS_ITEMS(state) {
    state = {
      items: [],
      pagination: {
        items_count: 0,
        last_page: 1,
      },
    }
  },
  SET_ORDER_ITEM(state, payload) {
    let tmp = []
    if (state.items.length) {
      state.items.forEach(item => {
        if (item.id === payload.id) {
          item = {
            ...item,
            ...payload,
          }
        }
        tmp.push(item)
      })
    } else  {
      tmp.push(payload)
    }
    state.items = tmp
  },
  SET_ORDERS_ITEMS(state, payload) {
    if (payload.data) payload = payload.data
    if (payload.items) {
      state.items = payload.items
    }
    state.pagination.last_page = payload.pagination ? payload.pagination.page_count : 1
    state.pagination.items_count = payload.pagination ? payload.pagination.items_count : 0
  },
  SET_PAYER_TO_ITEM(state, payload) {
    let tmp = []
    state.items.forEach(item => {
      let itemTmp = item
      if (item.id.toString() === payload.order_id.toString()) {
        itemTmp = {
          ...item,
          payer_detail: payload.payer,
        }
      }
      tmp.push(itemTmp)
    })
    state.items = tmp
  },
  // idOrder, idProgram, idListener
  REMOVE_LISTENER_ITEM(state, payload) {
    let tpm = []
    state.items.forEach(item => {
      if (item.id === payload.idOrder) {
        item = {
          ...item,
          ...{
            bids: item.bids.filter(item => item.id !== payload.idProgram),
          },
        }
      }
      tpm.push(item)
    })
    state.items = tpm
  },
}
