export default {
  itemsOrder: state => idOrder => {
    if (state.itemsOrder.length) {
      return state.itemsOrder.filter(item => Number(idOrder) === Number(item.idOrder))
    }
    return []
  },
  items: state => state.items,
  deals: state => state.deals,
  last_page: state => state.pagination.last_page,
  deals_last_page: state => state.pagination.deals.last_page,
  itemById: state => id => {
    for (let key in state.items) {
      if (state.items.hasOwnProperty(key) && Number(state.items[key].id) === Number(id)) {
        return state.items[key]
      }
    }
    return null
  },
}
