import axios from 'axios'
import appOptions from '@/config/options'

const sleep = m => new Promise(r => setTimeout(r, m))

export default {
  async fetchUpdatePayer({ rootState, commit }, params) {
    try {
      let path = ''
      let roles = rootState.user.user ? rootState.user.user.role ? [rootState.user.user.role] : Array.isArray(rootState.user.user.roles) ? rootState.user.user.roles : [rootState.user.user.roles] : []
      roles.forEach(role => {
        switch (role) {
          case 'contact_person':
            path = `/contact_person/orders/${params.order_id}/payer/edit`
            break
          case 'sales_department_manager':
            path = `/mop/orders/${params.order_id}/payer/edit`
            break
          case 'head_department_manager':
            path = `/rop/orders/${params.order_id}/payer/edit`
            break
        }
      })

      if (!rootState.auth.token) return { error: true }
      const res = await axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
        data: params,
      })

      await res.data

      if (!res.data.success && res.data.message) {
        commit('toast/NEW', { type: 'error', message: res.data.message }, { root: true })
      } else {
        commit('SET_PAYER_TO_ITEM', { payer: res.data.data, order_id: params.order_id })
      }

      return res.data ? res.data : res.error
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err
    }
  },
  async fetchOrderItem({ rootState, commit }, id) {
    try {
      if (!rootState.auth.token) return { error: true }

      let path = ''
      let roles = rootState.user.user ? rootState.user.user.role ? [rootState.user.user.role] : Array.isArray(rootState.user.user.roles) ? rootState.user.user.roles : [rootState.user.user.roles] : []
      roles.forEach(role => {
        switch (role) {
          case 'contact_person':
            path = `/contact_person/orders/${id}/get`
            break
          case 'sales_department_manager':
            path = `/mop/orders/${id}/get`
            break
          case 'head_department_manager':
            path = `/rop/orders/${id}/get`
            break
        }
      })

      const res = await axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
      })
      await res.data ? commit('SET_ORDER_ITEM', res.data.data) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })
      return res.data ? res.data : res.error
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err
    }
  },
  async fetchOrderItems({ rootState, commit }, params) {
    try {
      if (!rootState.auth.token) return { error: true }

      let path = ''
      let roles = rootState.user.user ? rootState.user.user.role ? [rootState.user.user.role] : Array.isArray(rootState.user.user.roles) ? rootState.user.user.roles : [rootState.user.user.roles] : []
      roles.forEach(role => {
        console.log('role', role)
        switch (role) {
          case 'contact_person':
            path = `/contact_person/orders/list`
            break
          case 'sales_department_manager':
            path = `/mop/orders/list`
            break
          case 'head_department_manager':
            path = `/rop/orders/list`
            break
        }
      })

      const res = await axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
        data: {
          page: params.page || 1,
          ...(params.filter ? params.filter : null),
        },
      })
      const result = await res.data
      result ? commit('SET_ORDERS_ITEMS', { page: params.page || 1, ...res.data }) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })
      return result
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err
    }
  },
  reset({ rootState, commit }) {
    return commit('RESET_ORDERS_ITEMS')
  },
}
