<template>
	<div>
		<div class="border-radius-lg border mb-20px">
			<div class="p-0 bg-white border-0 d-flex align-items-center justify-content-between p-20px cursor-pointer" @click="visible = !visible">
				<div class="fs-20px fw-600" v-if="bid.program">Программа "{{bid.program.name}}"</div>
				<IconChevronDown :style="`transition: .2s ease all; transform: rotate(${visible ? '0deg' : '180deg'})`" />
			</div>
			<b-collapse :id="`accordion_${bid.id}`" v-model="visible" :accordion="`my-accordion_${bid.id}`">
				<div class="p-0 bg-white border-0  px-20px pb-20px">
					<div>

						<div class="row mb-30px" v-if="bid.configuration">
							<div class="col-12 col-sm-4 d-flex flex-column gap-3">
								<dl class="d-flex gap-2 mb-0" v-if="bid.configuration.hours_volume">
									<dt class="text-muted w-150px">Объем часов</dt>
									<dd class="mb-0">{{bid.configuration.hours_volume}}</dd>
								</dl>
								<dl class="d-flex gap-2 mb-0" v-if="bid.configuration.study_form">
									<dt class="text-muted w-150px">Форма обучения</dt>
									<dd class="mb-0">{{bid.configuration.study_form.name}}</dd>
								</dl>
								<dl class="d-flex gap-2 mb-0" v-if="bid.program.type_educational_program">
									<dt class="text-muted w-150px">Вид программы</dt>
									<dd class="mb-0">{{bid.program.type_educational_program.name}}</dd>
								</dl>
								<dl class="d-flex gap-2 mb-0" v-if="bid.configuration.price">
									<dt class="text-muted w-150px">Стоимость</dt>
									<dd class="mb-0">{{$formatPrice(bid.configuration.price)}}</dd>
								</dl>
							</div>
							<div class="col-12 col-sm-8 d-flex flex-column">
								<div class="h6 mb-15px">Блоки программы</div>

								<div class="d-flex flex-column gap-2">
									<div v-for="group in bid.program.group_program" :key="`group_${bid.id}_${group.id}`" v-if="group.type === 'mandatory'">
										<ul class="list-unstyled d-flex flex-column gap-1 mb-0" v-if="group.blocks.length">
											<li v-for="block in group.blocks" :key="`block_${bid.id}_${block.id}`">{{block.name}}</li>
										</ul>
									</div>
								</div>
							</div>
						</div>

						<div class="fs-20px fw-600 mb-20px">Параметры обучения</div>

						<div class="row mb-30px" v-if="bid.training_format">
							<div class="col-12 col-sm-6 col-lg-3">
								<div class="fs-14px fw-600 mb-12px">Формат обучения</div>
								{{bid.training_format.name}}
							</div>
							<div class="col-12 col-sm-6 col-lg-3" v-if="bid.thread">
								<div class="fs-14px fw-600 mb-12px">Даты потоков обучения</div>
								{{bid.thread.name}}, {{bid.thread.date_start}} - {{bid.thread.date_end}}
							</div>
							<div class="col-12 col-sm-6 col-lg-3" v-if="bid.volume_academic_workload">
								<div class="fs-14px fw-600 mb-12px">Объем учебной нагрузки в день</div>
								{{bid.volume_academic_workload}} а.ч.
							</div>
							<div class="col-12 col-sm-6 col-lg-3" v-if="bid.training_regimes">
								<div class="fs-14px fw-600 mb-12px">Режим занятий</div>
								<span v-for="item in bid.training_regimes" :key="`regime_${item.id}`">{{training_regimes[item.day - 1]}} </span>
							</div>
						</div>

						<template v-if="bid.configuration">
							<div class="fs-14px fw-600 mb-12px">Дополнительные блоки:</div>

							<div class="d-flex flex-wrap gap-5 mb-30px">
								<div class="mw-sm-45" v-for="group in bid.configuration.group_program" :key="`dop_group_${group.id}_${bid.id}`">
									<div class="fs-14px fw-500 mb-8px">{{group.name}}</div>
									<ul class="list-unstyled mb-0 d-flex flex-column gap-2" v-if="group.blocks.length">
										<li v-for="block in group.blocks" :key="`block_${bid.id}_${block.id}`">{{block.name}}</li>
									</ul>
								</div>
							</div>
						</template>

						<div class="mb-30px" v-if="bid.motivation_letter && bid.motivation_letter.id">
							<div class="fs-16px fw-600 mb-12px">Мотивационное письмо</div>

							<a :href="bid.motivation_letter.file_link" :download="bid.motivation_letter.file_name" class="text-decoration-underline">{{bid.motivation_letter.file_name}}</a>
						</div>

						<div class="d-flex flex-wrap gap-4 mt-30px">
							<router-link :to="`/education/programs/${bid.id}`" class="btn btn-outline-default text-dark px-30px py-10px">Подробнее</router-link>
						</div>

					</div>
				</div>
			</b-collapse>
		</div>
	</div>
</template>

<script>
  import IconChevronDown from '@/components/icons/IconChevronDown'
  import uniqid from 'uniqid'

  export default {
    name: 'EducationBidConfirmedCard',
    components: { IconChevronDown },
    props: ['bid'],
    data() {
      return {
        visible: false,
        training_regimes: [ 'Понедельник', 'Вторник', 'Среда','Четверг','Пятница','Суббота','Воскресенье' ],
      }
    },
    computed: {
      token() {
        return this.$store.getters['auth/token']
      },
    },
    watch: {
    },
    mounted() {
    },
    methods: {
    },
  }
</script>
