<template>
	<div>
		<ValidationObserver tag="form"
							@submit.prevent="submitForm"
							ref="observer-form"
							v-slot="{ invalid }"
							method="POST">

			<div class="row">
				<ValidationProvider tag="div" class="col-12 mb-15px position-relative" rules="required:true" name="Наличие ВПО/СПО" mode="lazy" v-slot="{ errors }">
					<div :class="'form-check' + (errors.length ? ' is-invalid' : '')">
						<input type="checkbox"
							   :disabled="!isEditable"
							   v-model="form['confirm_vo_spo']"
							   id="field-confirm_vo_spo"
							   :class="errors.length ? 'is-invalid' : ''"
							   class="form-check-input" />
						<label for="field-confirm_vo_spo" class="form-label">Подтверждаю наличие образования (высшего или средне профессионального) или получение такого вида образования в настоящее время</label>
					</div>
					<div v-if="errors.length" class="invalid-tooltip">
						<span v-for="err in errors" :key="'field-confirm_vo_spo_err'">{{err}}</span>
					</div>

				</ValidationProvider>

				<div class="col-12 col-sm-6 mb-15px position-relative">
					<label class="form-label">Гражданство*</label>

					<v-select
							:disabled="!isEditable"
							:class="!isEditable ? 'disabled' : ''" :options="citizenshipList" :reduce="item => item.code" label="label" :clearable="false" :searchable="false" v-model="form.citizenship"></v-select>
				</div>

				<ValidationProvider
						tag="div"
						class="col-12 col-sm-6 mb-15px position-relative"
						rules="required"
						name="Фамилия"
						mode="lazy"
						v-slot="{ errors }">
					<InputField
							:type-field="`text`"
							:disabled="!isEditable"
							:errors-field="errors"
							:name-field="`Фамилия*`"
							:filter-field="`[0-9а-яА-ЯёЁa-zA-Z \-]`"
							:placeholder-field="`Введите фамилию`"
							v-model="form.surname"
					/>
				</ValidationProvider>

				<ValidationProvider
						tag="div"
						class="col-12 col-sm-6 mb-15px position-relative"
						rules="required"
						name="Имя"
						mode="lazy"
						v-slot="{ errors }">
					<InputField
							:type-field="`text`"
							:errors-field="errors"
							:disabled="!isEditable"
							:name-field="`Имя*`"
							:filter-field="`[0-9а-яА-ЯёЁa-zA-Z \-]`"
							:placeholder-field="`Введите имя`"
							v-model="form.name"
					/>
				</ValidationProvider>

				<ValidationProvider
						tag="div"
						class="col-12 col-sm-6 mb-15px position-relative"
						rules="required"
						name="Отчество"
						mode="lazy"
						v-slot="{ errors }">
					<InputField
							:type-field="`text`"
							:errors-field="errors"
							:disabled="!isEditable"
							:name-field="`Отчество*`"
							:filter-field="`[0-9а-яА-ЯёЁa-zA-Z \-]`"
							:placeholder-field="`Введите отчество`"
							v-model="form.patronymic"
					/>
				</ValidationProvider>

				<ValidationProvider ref="phone" tag="div" class="col-12 col-sm-6 mb-15px position-relative" rules="required|phone" name="Телефон" mode="lazy" v-slot="{ errors }">

					<label for="field-phone" class="form-label">Телефон*</label>

					<masked-input type="text"
								  class="form-control"
								  :class="errors.length ? 'is-invalid' : ''"
								  :placeholder="'+7 (999) 999-99-99'"
								  :name="'field-phone'"
								  id="field-phone"
								  :disabled="!isEditable"
								  :pattern="'+7 (111) 111-11-11'"
								  v-model="form['phone']">

					</masked-input>

					<div v-if="errors.length" class="invalid-tooltip">
						<span v-for="err in errors" :key="'field-phone_err'">{{err}}</span>
					</div>

				</ValidationProvider>

				<ValidationProvider
						tag="div"
						class="col-12 col-sm-6 mb-15px position-relative"
						rules="required|snils"
						name="СНИЛС"
						mode="lazy"
						v-slot="{ errors }"
						v-if="isRussia">

					<label for="field-snils" class="form-label">СНИЛС*</label>

					<masked-input type="text"
								  class="form-control"
								  :class="errors.length ? 'is-invalid' : ''"
								  placeholder="___-___-___-__"
								  name="field-snils"
								  id="field-snils"
								  :disabled="!isEditable"
								  pattern="111-111-111-11"
								  v-model="form.snils">

					</masked-input>

					<div v-if="errors.length" class="invalid-tooltip">
						<span v-for="err in errors" :key="'field-snils_err'">{{err}}</span>
					</div>

				</ValidationProvider>

				<ValidationProvider
						tag="div"
						class="col-12 col-sm-6 mb-15px position-relative"
						rules="required|email"
						name="Эл. адрес"
						mode="lazy"
						v-slot="{ errors }">
					<InputField
							:type-field="`text`"
							:errors-field="errors"
							:name-field="`Эл. адрес*`"
							:disabled=" !isEditable"
							:placeholder-field="`example@mail.ru`"
							:filter-field="`[0-9a-z@_\.]`"
							v-model="form.email"
					/>
				</ValidationProvider>

				<ValidationProvider
						tag="div"
						:class="`col-12 ${isRussia ? 'col-sm-6' : 'col-sm-12'} mb-15px position-relative`"
						rules="required"
						name="Полное ФИО в родительном падеже"
						mode="lazy"
						v-slot="{ errors }">
					<InputField
							:type-field="`text`"
							:disabled="!isEditable"
							:errors-field="errors"
							:name-field="`Полное ФИО в родительном падеже*`"
							:filter-field="`[0-9а-яА-ЯёЁa-zA-Z \-]`"
							v-model="form.genitive_fio"
					/>
				</ValidationProvider>

				<ValidationProvider
						tag="div"
						class="col-12 col-sm-4 mb-15px position-relative"
						rules="required"
						name="Дата рождения"
						mode="lazy"
						v-slot="{ errors }">
					<InputField
							:type-field="`date`"
							:disabled="!isEditable"
							:errors-field="errors"
							:name-field="`Дата рождения*`"
							v-model="form.birthday"
					/>
				</ValidationProvider>

				<ValidationProvider
						tag="div"
						:class="`col-12 col-sm-4 mb-15px position-relative`"
						rules="required"
						:name="isRussia ? `Серия и номер паспорта` : `Удостоверение личности/иной документ`"
						mode="lazy"
						v-slot="{ errors }">
					<template v-if="isRussia">
						<label for="field-snils" class="form-label">Серия и номер паспорта*</label>

						<masked-input type="text"
									  class="form-control"
									  :class="errors.length ? 'is-invalid' : ''"
									  placeholder="XXXX XXXXXX"
									  :disabled="!isEditable"
									  name="field-passport_series_number"
									  id="field-passport_series_number"
									  pattern="1111 111111"
									  v-model="form.passport_series_number">
						</masked-input>

						<div v-if="errors.length" class="invalid-tooltip">
							<span v-for="err in errors" :key="'field-snils_err'">{{err}}</span>
						</div>
					</template>
					<template v-else>
						<InputField
								:type-field="`text`"
								:errors-field="errors"
								:disabled="!isEditable"
								:name-field="`Удостоверение личности/иной документ*`"
								v-model="form.passport_series_number"
						/>
					</template>
				</ValidationProvider>

				<ValidationProvider
						tag="div"
						:class="`col-12 col-sm-4 mb-15px position-relative`"
						:rules="isRussia? 'required' : ''"
						:name="`Дата выдачи`"
						mode="lazy"
						v-slot="{ errors }">
					<InputField
							:type-field="`date`"
							:disabled="!isEditable"
							:errors-field="errors"
							:name-field="`Дата выдачи${isRussia ? '*' : ''}`"
							v-model="form.passport_date_issue"
					/>
				</ValidationProvider>

				<ValidationProvider
						tag="div"
						:class="`col-12 mb-15px position-relative`"
						:rules="isRussia? 'required' : ''"
						:name="`Кем выдан`"
						mode="lazy"
						v-slot="{ errors }">
					<InputField
							:type-field="`text`"
							:disabled="!isEditable"
							:errors-field="errors"
							:name-field="`Кем выдан${isRussia ? '*' : ''}`"
							v-model="form.passport_issued_by_whom"
					/>
				</ValidationProvider>

				<ValidationProvider
						tag="div"
						:class="`col-12 mb-15px position-relative`"
						:name="`Адрес места жительства`"
						rules="required"
						mode="lazy"
						v-slot="{ errors }">
					<InputField
							:type-field="`text`"
							:disabled="!isEditable"
							:errors-field="errors"
							:name-field="`Адрес места жительства *`"
							v-model="form.passport_residential_address"
					/>
				</ValidationProvider>

				<ValidationProvider
						tag="div"
						:class="`col-12 col-sm-6 mb-15px position-relative`"
						:name="`Ник телеграм`"
						rules="telegram"
						mode="lazy"
						v-slot="{ errors }">
					<InputField
							:type-field="`text`"
							:disabled="!isEditable"
							:errors-field="errors"
							:name-field="`Ник телеграм`"
							v-model="form.telegram"
					/>
				</ValidationProvider>

				<div class="fs-22px fw-500 mt-24px mb-12px">Наличие образования</div>

				<ValidationProvider
						tag="div"
						:class="`col-12 mb-15px position-relative`"
						:name="`Наличие образования`"
						rules="required"
						mode="lazy"
						v-slot="{ errors }">
					<div class="row">
						<div class="col-12 col-sm-4" v-for="item in educationStatusList" :key="`education-item_${item.code}`">
							<div class="form-check">
								<input type="radio"
									   :disabled="!isEditable"
									   :name="`education-item`"
									   :id="`education-item_${item.code}`"
									   class="form-check-input"
									   :value="item.code"
									   :class="errors.length ? 'is-invalid' : ''"
									   v-model="form.education_status">
								<label :for="`education-item_${item.code}`"
									   class="form-check-label">{{item.label}}</label>
							</div>
						</div>
					</div>
				</ValidationProvider>

				<ValidationProvider
						tag="div"
						:class="`col-12 col-sm-6 mb-15px position-relative`"
						:name="`Образовательная организация*`"
						rules="required"
						mode="lazy"
						v-slot="{ errors }">
					<InputField
							:type-field="`text`"
							:disabled="!isEditable"
							:errors-field="errors"
							:name-field="`Образовательная организация`"
							v-model="form.education_organization"
					/>
				</ValidationProvider>

				<ValidationProvider
						tag="div"
						class="col-6 mb-15px position-relative"
						rules="required"
						name="Направление подготовки"
						v-slot="{ errors }">
					<label class="form-label">Направление подготовки*</label>
					<v-select
							:class="{'is-invalid': errors.length, 'disabled': !isEditable}"
							:disabled="!isEditable"
							:options="trainingDirectionList"
							:reduce="item => item.code"
							label="label"
							:clearable="false"
							:searchable="false"
							v-model="form.education_direction_training_id"></v-select>
					<div v-if="errors.length" class="invalid-tooltip">
						<span v-for="err in errors" :key="'field-confirm_vo_spo_err'">{{err}}</span>
					</div>
				</ValidationProvider>

				<ValidationProvider
						tag="div"
						class="col-6 mb-15px position-relative"
						rules="required"
						name="Квалификация/специальность"
						v-slot="{ errors }">
					<label class="form-label">Квалификация/специальность*</label>
					<v-select
							:class="{'is-invalid': errors.length, 'disabled': !isEditable}"
							:options="qualificationList"
							:disabled="!isEditable"
							:reduce="item => item.code"
							label="label"
							:clearable="false"
							:searchable="false"
							v-model="form.education_qualification_id"></v-select>
					<div v-if="errors.length" class="invalid-tooltip">
						<span v-for="err in errors" :key="'field-confirm_vo_spo_err'">{{err}}</span>
					</div>
				</ValidationProvider>

				<ValidationProvider
						tag="div"
						:class="`col-12 col-sm-6 mb-15px position-relative`"
						:name="`Серия и номер диплома об образовании`"
						:rules="form.education_status === 'exist' ? 'required' : ''"
						mode="lazy"
						v-slot="{ errors }">
					<InputField
							:type-field="`text`"
							:disabled="!isEditable"
							:errors-field="errors"
							:placeholder-field="`ХХХХХХХХХXXXX`"
							:name-field="`Серия и номер диплома об образовании${form.education_status === 'exist' ? '*' : ''}`"
							v-model="form.education_series_number_document"
					/>
				</ValidationProvider>

				<ValidationProvider
						tag="div"
						class="col-12 col-sm-6 mb-15px position-relative"
						:rules="form.education_status === 'exist' ? 'required' : ''"
						name="Дата выдачи документа об образовании"
						mode="lazy"
						v-slot="{ errors }">
					<InputField
							:type-field="`date`"
							:disabled="!isEditable"
							:errors-field="errors"
							:name-field="`Дата выдачи документа об образовании${form.education_status === 'exist' ? '*' : ''}`"
							v-model="form.education_date_issue_document"
					/>
				</ValidationProvider>

				<ValidationProvider
						tag="div"
						:class="`col-12 col-sm-6 mb-15px position-relative`"
						:name="`Рег. номер документа об образовании`"
						:rules="form.education_status === 'exist' ? 'required' : ''"
						mode="lazy"
						v-slot="{ errors }">
					<InputField
							:type-field="`text`"
							:disabled="!isEditable"
							:errors-field="errors"
							:name-field="`Рег. номер документа об образовании${form.education_status === 'exist' ? '*' : ''}`"
							v-model="form.education_registration_number_document"
					/>
				</ValidationProvider>

				<ValidationProvider
						tag="div"
						:class="`col-12 col-sm-6 mb-15px position-relative`"
						:name="`ФИО в документе об образовании`"
						:rules="form.education_status === 'exist' ? 'required' : ''"
						mode="lazy"
						v-slot="{ errors }">
					<InputField
							:type-field="`text`"
							:disabled="!isEditable"
							:errors-field="errors"
							:name-field="`ФИО в документе об образовании${form.education_status === 'exist' ? '*' : ''}`"
							v-model="form.education_fio_in_document"
					/>
					<div class="text-gray-700 mt-8px fs-12px">*Отчество не является обязательным к заполнению</div>
				</ValidationProvider>

				<div class="fs-22px fw-500 mt-24px mb-12px">Статус наличия трудовых отношений</div>

				<ValidationProvider
						tag="div"
						:class="`col-12 mb-15px position-relative`"
						:name="`Наличие образования`"
						rules="required"
						mode="lazy"
						v-slot="{ errors }">
					<div class="row">
						<div class="col-12 col-sm-4" v-for="item in relationshipList" :key="`relationship-item_${item.code}`">
							<div class="form-check">
								<input type="radio"
									   :disabled="!isEditable"
									   :name="`relationship-item`"
									   :id="`relationship-item_${item.code}`"
									   class="form-check-input"
									   :value="item.code"
									   :class="errors.length ? 'is-invalid' : ''"
									   v-model="form.labor_relations_status">
								<label :for="`relationship-item_${item.code}`"
									   class="form-check-label">{{item.label}}</label>
							</div>
						</div>
					</div>
				</ValidationProvider>

				<ValidationProvider
						tag="div"
						:class="`col-12 col-sm-6 mb-15px position-relative`"
						:name="`Основное место работы`"
						:rules="form.labor_relations_status === 'in_relationship' ? 'required' : ''"
						mode="lazy"
						v-slot="{ errors }">
					<InputField
							:type-field="`text`"
							:disabled="!isEditable"
							:errors-field="errors"
							:name-field="`Основное место работы`"
							v-model="form.labor_relations_main_place_work"
					/>
				</ValidationProvider>

				<ValidationProvider
						tag="div"
						:class="`col-12 col-sm-6 mb-15px position-relative`"
						:name="`Должность`"
						:rules="form.labor_relations_status === 'in_relationship' ? 'required' : ''"
						mode="lazy"
						v-slot="{ errors }">
					<InputField
							:type-field="`text`"
							:disabled="!isEditable"
							:errors-field="errors"
							:name-field="`Должность`"
							v-model="form.labor_relations_job_title"
					/>
				</ValidationProvider>

				<ValidationProvider
						tag="div"
						class="col-6 mb-15px position-relative"
						:rules="form.labor_relations_status === 'in_relationship' ? 'required' : ''"
						name="Категория должности"
						v-slot="{ errors }">
					<label class="form-label">Укажите к какой категории относится должность</label>
					<v-select
							:class="{'is-invalid': errors.length, 'disabled': !isEditable}"
							:options="jobCategoryList"
							:disabled="!isEditable"
							:reduce="item => item.code"
							label="label"
							:clearable="false"
							:searchable="false"
							v-model="form.labor_relations_job_category_id"></v-select>
					<div v-if="errors.length" class="invalid-tooltip">
						<span v-for="err in errors" :key="'field-confirm_vo_spo_err'">{{err}}</span>
					</div>
				</ValidationProvider>

			</div>

			<div class="d-flex flex-wrap gap-4">
				<button type="submit" class="btn btn-primary px-30px py-10px mt-30px" :disabled="!isEditable">Подтвердить данные</button>

				<!--<router-link to="/profile/documents" class="btn btn-outline-success px-30px py-10px mt-30px">Запрос на актуализацию данных</router-link>-->
			</div>
		</ValidationObserver>

		<b-modal :id="'NextToDocumentsModal'" size="md" centered>
			<template slot="modal-header">
				<h4 class="modal-title w-100 text-center">Спасибо!</h4>
				<a href="javascript:void(0)" class="btn-close" @click="$bvModal.hide('NextToDocumentsModal')"></a>
			</template>

			<div class="text-center">
				Вы заполнили анкету. Заключительным шагом, пожалуйста, загрузите сканы подтверждающих документов
			</div>

			<div slot="modal-footer" class="d-flex justify-content-center w-100 gap-3">
				<button type="button" @click="() => {$emit('goToDocuments'), $bvModal.hide('NextToDocumentsModal')}" class="btn btn-primary btn-lg">
					OK
				</button>
			</div>
		</b-modal>
	</div>
</template>

<script>
  import axios from 'axios'
  import InputField from '@/components/elements/Input'

  export default {
    name: 'ListenerQuestionnaire',
    components: { InputField },
    data() {
      return {
        educationStatusList: [{ code: 'exist', label: 'Есть высшее / среднее профессиональное' }, { code: 'not_exist', label: 'Получаю высшее / среднее профессиональное' }],
        relationshipList: [{ code: 'in_relationship', label: 'Состою в трудовых отношениях' }, { code: 'not_in_relationship', label: 'Не состою в трудовых отношениях' }],
        citizenshipList: [{ code: 'rus', label: 'Российское' }, { code: 'another', label: 'Другое' }],
        form: {
          confirm_vo_spo: false,
          citizenship: 'rus',
          surname: '',
          name: '',
          patronymic: '',
          genitive_fio: '',
          age: '',
          birthday: '',
          telegram: '',
          passport_series_number: '',
          passport_date_issue: '',
          passport_issued_by_whom: '',
          passport_residential_address: '',
          education_status: '',
          education_organization: '',
          education_direction_training_id: null,
          education_qualification_id: null,
          education_series_number_document: '',
          education_date_issue_document: '',
          education_registration_number_document: '',
          education_fio_in_document: '',
          labor_relations_status: '',
          labor_relations_main_place_work: '',
          labor_relations_job_title: '',
          labor_relations_job_category_id: null,
        },
      }
    },
    computed: {
      token() {
        return this.$store.getters['auth/token']
      },
      profile() {
        return this.$store.getters['user/profile']
      },
      jobCategoryList() {
        let items = this.$store.getters['dictionaries/items']('job_category')
        return items && items.length ? items.map(item => ({ code: item.id, label: item.name })) : []
      },
      trainingDirectionList() {
        let items = this.$store.getters['dictionaries/items']('training_direction')
        return items && items.length ? items.map(item => ({ code: item.id, label: item.name })) : []
      },
      qualificationList() {
        let items = this.$store.getters['dictionaries/items']('qualification')
        return items && items.length ? items.map(item => ({ code: item.id, label: item.name })) : []
      },
      isRussia() {
        return this.form.citizenship === 'rus'
      },
      user() {
        return this.$store.getters['user/user']
      },
      isEditable() {
        return this.user ? this.user.need_edit_profile : false
      },
    },
    watch: {
      profile(newVal, oldVal) {
        if (newVal && JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.form.confirm_vo_spo = newVal.confirm_vo_spo
          this.form.surname = newVal.surname
          this.form.name = newVal.name
          this.form.patronymic = newVal.patronymic
          this.form.citizenship = newVal.citizenship
          this.form.phone = this.$formatPhone(newVal.phone)
          this.form.snils = newVal.snils.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/g, `$1-$2-$3-$4`)
          this.form.email = newVal.email
          this.form.genitive_fio = newVal.genitive_fio
          this.form.birthday = newVal.birthday
          this.form.telegram = newVal.telegram
          this.form.passport_series_number = this.form.citizenship === 'russia' ? newVal.passport.series_number.replace(/^(\d{4})(\d{6})$/g, `$1 $2`) : newVal.passport.series_number
          this.form.passport_residential_address = newVal.passport.residential_address
          this.form.passport_date_issue = newVal.passport.date_issue
          this.form.passport_issued_by_whom = newVal.passport.issued_by_whom
          this.form.education_fio_in_document = newVal.education.fio_in_document
          this.form.education_registration_number_document = newVal.education.registration_number_document
          this.form.education_date_issue_document = newVal.education.date_issue_document
          this.form.education_series_number_document = newVal.education.series_number_document
          this.form.education_qualification_id = newVal.education.qualification_id
          this.form.education_direction_training_id = newVal.education.direction_training_id
          this.form.education_organization = newVal.education.organization
          this.form.education_status = newVal.education.status
          this.form.labor_relations_job_category_id = newVal.labor_relations.job_category_id
          this.form.labor_relations_job_title = newVal.labor_relations.job_title
          this.form.labor_relations_main_place_work = newVal.labor_relations.main_place_work
          this.form.labor_relations_status = newVal.labor_relations.status
        }
      },
    },
    mounted() {
      this.$store.dispatch('user/fetchGetUserProfile')
        .then(res => {
          if (res.data?.user_id) {
            res = res.data
            this.form.confirm_vo_spo = res.confirm_vo_spo
            this.form.surname = res.surname
            this.form.name = res.name
            this.form.patronymic = res.patronymic
            this.form.citizenship = res.citizenship
            this.form.phone = this.$formatPhone(res.phone)
            this.form.snils = res.snils.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/g, `$1-$2-$3-$4`)
            this.form.email = res.email
            this.form.genitive_fio = res.genitive_fio
            this.form.birthday = res.birthday
            this.form.telegram = res.telegram
            this.form.passport_series_number = res.citizenship === 'russia' ? res.passport.series_number.replace(/^(\d{4})(\d{6})$/g, `$1 $2`) : res.passport.series_number
            this.form.passport_residential_address = res.passport.residential_address
            this.form.passport_date_issue = res.passport.date_issue
            this.form.passport_issued_by_whom = res.passport.issued_by_whom
            this.form.education_fio_in_document = res.education.fio_in_document
            this.form.education_registration_number_document = res.education.registration_number_document
            this.form.education_date_issue_document = res.education.date_issue_document
            this.form.education_series_number_document = res.education.series_number_document
            this.form.education_qualification_id = res.education.qualification_id
            this.form.education_direction_training_id = res.education.direction_training_id
            this.form.education_organization = res.education.organization
            this.form.education_status = res.education.status
            this.form.labor_relations_job_category_id = res.labor_relations.job_category_id
            this.form.labor_relations_job_title = res.labor_relations.job_title
            this.form.labor_relations_main_place_work = res.labor_relations.main_place_work
            this.form.labor_relations_status = res.labor_relations.status
          }
        })
      this.$store.dispatch('dictionaries/fetchDictionary', { type: 'job_category' })
      this.$store.dispatch('dictionaries/fetchDictionary', { type: 'training_direction' })
      this.$store.dispatch('dictionaries/fetchDictionary', { type: 'qualification' })
    },
    methods: {
      calculateAge(birthday) {
        birthday = birthday.split('.')
        birthday = [birthday[2], birthday[1], birthday[0]].join('-')
        let date = new Date(birthday)
        let ageDifMs = new Date().getTime() - date.getTime()
        let ageDate = new Date(ageDifMs)
        return Math.abs(ageDate.getUTCFullYear() - 1970)
      },
      async submitForm() {
        if (!this.isEditable) return false
        const isValid = await this.$refs['observer-form'].validate()
        if (isValid) {
          let data = JSON.parse(JSON.stringify(this.form))
          Object.keys(data).reduce((acc, k) => (!data[k] && delete acc[k], acc), data)
          data.age = this.calculateAge(data.birthday) || 1
          this.$store.dispatch('user/fetchUpdateUserProfile', data)
            .then(res => {
              if (res.message) {
                this.$notify({
                  group: 'center',
                  title: ``,
                  text: res.message,
                  type: 'error',
                })
              } else {
                this.$bvModal.show('NextToDocumentsModal')
                this.$store.dispatch('user/fetchGetUser')
              }
            })
        }

      },
    },
  }
</script>
