<template>
	<div>
		<!-- BEGIN page-header -->
		<div class="d-flex flex-wrap flex-row align-items-end gap-4 mb-25px">
			<h1 class="page-header mb-0">Информация о программе</h1>
		</div>
		<!-- END page-header -->
		<div class="mw-1200px" v-if="bid">

			<span :class="'border-radius-xl px-12px py-7px bg-yellow text-dark mb-20px d-inline-flex'" v-if="bid.status">Статус: {{bid.status.name}}</span>

			<div class="d-flex gap-3 align-items-stretch mb-24px mw-1200px">
				<div class="border border-2 border-radius-lg px-16px py-16px flex-1" v-if="curator">
					<div class="d-flex align-items-center">
						<div class="w-65px h-65px rounded-pill overflow-hidden d-flex align-items-center justify-content-center fs-16px bg-gray">
							{{curator.surname[0]}}{{curator.name[0]}}
						</div>
						<div class="ms-12px me-auto">
							<div class="fs-12px fw-500">Ваш куратор</div>
							<div class="mt-6px fs-14px fw-500">{{[curator.surname, curator.name, curator.patronymic].join(' ')}}</div>
							<div class="mt-6px fs-12px fw-500" v-if="curator.phone || curator.email">
								<a :href="'tel:' + curator.phone" v-if="curator.phone">{{$formatPhone(curator.phone)}}</a>
								<a :href="'mailto:' + curator.email" v-if="curator.email">{{curator.email}}</a>
							</div>
						</div>
						<a href="javascript:;" class="btn btn-primary btn-sm py-8px px-16px ms-12px">Написать в чат</a>
					</div>
				</div>
				<div class="border border-2 border-radius-lg px-16px py-16px flex-1 d-flex align-items-center justify-content-between">
					<div class="fs-16px fw-600 mb-10px">Запросить документы<br />по образовательному процессу</div>
					<a href="javascript:;" class="btn btn-primary btn-sm py-8px px-16px nowrap ms-12px" @click="$bvModal.show('CallbackModal')">Заполнить запрос</a>
				</div>
			</div>

			<b-modal :id="'CallbackModal'" size="md" centered>
				<template slot="modal-header">
					<h4 class="modal-title w-100 text-center">Запросить документы
						по&nbsp;образовательному процессу</h4>
					<a href="javascript:void(0)" class="btn-close" @click="$bvModal.hide('CallbackModal')"></a>
				</template>

				<div class="form-check" v-for="item in doc_list" :key="`doc_${item.code}`">
					<input type="checkbox"
						   v-model="form.doc_list"
						   :id="`form_${item.code}`"
						   :value="item.code"
						   class="form-check-input" />
					<label :for="`form_${item.code}`" class="form-label">{{item.name}}</label>
				</div>

				<div slot="modal-footer" class="d-flex justify-content-center w-100 gap-3">
					<button type="button" @click="sendCallback" class="btn btn-primary btn-lg">
						Запросить
					</button>
				</div>
			</b-modal>

			<div class="border border-2 border-radius-lg  px-24px pt-18px pb-24px mb-24px">
				<div class="d-flex align-items-center justify-content-between mb-16px gap-3">
					<div class="fs-18px fw-600">{{program.name}}</div>
					<b-dropdown toggle-class="p-0" variant="link" right v-if="bid.contract_number">
						<template #button-content>
							<a href="javascript:;" class="btn btn-primary btn-sm px-12px py-8px">Договор №{{bid.contract_number}}</a>
						</template>
						<div class="dropdown-text">
							<dl class="row mb-0 w-300px px-16px">
								<template>
									<dt class="col-md-5">Дата</dt>
									<dd class="col-md-7">{{bid.contract_date}} а.ч.</dd>
								</template>
								<!--<template>
									<dt class="col-md-5">Плательщик</dt>
									<dd class="col-md-7">{{bid.configuration.study_form?.name}}</dd>
								</template>-->
							</dl>
						</div>
					</b-dropdown>
				</div>

				<dl class="row mb-0">
					<template v-if="bid.configuration">
						<dt class="col-md-3">Объем часов</dt>
						<dd class="col-md-9">{{bid.configuration.hours_volume}} а.ч.</dd>
					</template>
					<template v-if="bid.configuration">
						<dt class="col-md-3">Форма обучения</dt>
						<dd class="col-md-9">{{bid.configuration.study_form?.name}}</dd>
					</template>
					<template v-if="program.issued_document_type">
						<dt class="col-md-3">Вид документа</dt>
						<dd class="col-md-9">{{program.issued_document_type.name}}</dd>
					</template>
					<template v-if="bid.thread">
						<dt class="col-md-3">Дата начала обучения</dt>
						<dd class="col-md-9">{{bid.thread.date_start}}</dd>
					</template>
					<template v-if="bid.thread">
						<dt class="col-md-3">Дата окончания обучения</dt>
						<dd class="col-md-9">{{bid.thread.date_end}}</dd>
					</template>
					<template v-if="bid.training_format">
						<dt class="col-md-3">Формат обучения</dt>
						<dd class="col-md-9">{{bid.training_format.name}}</dd>
					</template>
					<template v-if="bid.volume_academic_workload">
						<dt class="col-md-3">Объем учебной нагрузки в день</dt>
						<dd class="col-md-9">{{bid.volume_academic_workload}} а.ч.</dd>
					</template>
				</dl>
			</div>

			<div class="border border-2 border-radius-lg  px-24px pt-18px pb-24px mb-24px">
				<div class="fs-18px fw-600 mb-16px">Этапы прохождения программы</div>

				<nav class="modules" v-if="bid.progress">
					<div class="modules-item" v-for="(item, index) in bid.progress" :key="`module_${index}`">
						<span class="modules-item__date-first-dot"></span>
						<div class="modules-item__date-first">{{item.date_start}}</div>
						<div class="modules-item__title">{{item.name}}</div>
						<div class="modules-item__date-second">{{item.date_end}}</div>
						<span class="modules-item__date-last-dot"></span>
					</div>
				</nav>
			</div>
		</div>

		<notifications group="center" position="top center" :duration="10000">
			<template slot="body" slot-scope="props">
				<div :class="'vue-notification-template vue-notification ' + props.item.type">
					<div class="title">
						{{props.item.title}}
					</div>
					<a class="close" @click="props.close">
						<i class="fa fa-times"></i>
					</a>
					<div v-html="props.item.text">
					</div>
				</div>
			</template>
		</notifications>
	</div>
</template>

<script>


  export default {
    name: 'EducationBidsConfirmedDetail',
    data() {
      return {
        page: 1,
        doc_list: [
          { code: '1', name: 'Выписка из приказа о зачислении' },
          { code: '2', name: 'Справка о прохождении обучения по программе' },
          { code: '3', name: 'Справка о пройденном обучении, в связи с получением ВО/СПО' },
        ],
        form: {
          doc_list: [],
        },
      }
    },
    computed: {
      user() {
        return this.$store.getters['user/user']
      },
      idBid() {
        return this.$route.params.id
      },
      bid() {
        return this.idBid ? this.$store.getters['bids/itemConfirmedById'](this.idBid) : null
      },
      program() {
        return this.bid ? this.bid.program : null
      },
      curator() {
        return this.program ? this.program.curator : null
      },
    },

    watch: {},

    mounted() {
      this.$store.dispatch('bids/fetchBidConfirmedItem', this.idBid)
        .then((res) => {
          this.loaded = true
          if (res.code === 401) {
            Promise.all([
              this.$store.dispatch('orders/reset'),
              this.$store.dispatch('notifications/reset'),
              this.$store.dispatch('documents/resetDocuments'),
              this.$store.dispatch('user/resetUser'),
              this.$store.dispatch('listeners/resetListeners'),
              this.$store.dispatch('bids/reset'),
              this.$store.dispatch('auth/logout'),
            ]).then(() => {
              this.$router.push({ path: '/login' })
            })
          }
        })
    },
    methods: {
      sendCallback() {
        let errors = []

        if (!this.form.doc_list.length) {
          errors.push('Выберите документы')
        }

        if (errors.length) {
          this.$notify({
            group: 'center',
            title: ``,
            text: errors.join('<br/>'),
            type: 'error',
          })
        } else {
          this.$notify({
            group: 'center',
            title: ``,
            text: `Отправлено`,
            type: 'success',
          })
		  this.$bvModal.hide('CallbackModal')
        }
      },
    },
  }
</script>
