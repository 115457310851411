<template>
	<div>
		<ValidationObserver
				tag="form"
				class="row mt-3"
				:ref="'payer_edit_observer' + fieldsForm.type_face"
				@submit.prevent="sendForm()"
				v-slot="{ invalid }"
				method="POST">

			<div :class="'col-12 col-sm-' + field.width + (field.offset ? 'offset-0 offset-sm-' + field.offset : '')"
				 v-for="(field, key) in typesFieldsMap"
				 :key="key">
				<ValidationProvider
						:ref="'form-payer-' + key"
						tag="div"
						class="mb-15px position-relative"
						:rules="field.rules"
						:name="field.label"
						mode="lazy"
						v-slot="{ errors }">
					<template v-if="field.tag === 'input'">
						<!---INPUT TEXT--->
						<template v-if="field.type === 'text'">

							<label :for="'form-payer-field-' + key"
								   class="form-label"
								   v-if="field.label"
								   v-html="field.label"></label>

							<input type="text"
								   class="form-control"
								   :class="errors.length ? 'is-invalid' : ''"
								   :placeholder="field.placeholder"
								   :name="'form-payer-field-' + key"
								   :id="'form-payer-field-' + key"
								   :maxlength="field.maxlength || 10000"
								   v-filter="field.filter"
								   @input="checkValidate(field.field_name || key)"
								   @change="checkValidate(field.field_name || key)"
								   v-model="form[field.field_name || key]" />

							<div v-if="errors.length" class="invalid-tooltip">
								<span v-for="err in errors" :key="'form-payer-field-' + key + '_err'">{{err}}</span>
							</div>

						</template>
						<!---DATE TEXT--->
						<template v-if="field.type === 'date'">

							<label :for="'form-payer-field-' + key"
								   class="form-label"
								   v-if="field.label"
								   v-html="field.label"></label>

							<date-picker
									:name="'form-payer-field-' + key"
									:id="'form-payer-field-' + key"
									class="form-control"
									:class="errors.length ? 'is-invalid' : ''"
									v-model="form[field.field_name || key]"
									@dp-change="checkValidate(field.field_name || key)"
									:config="options"></date-picker>

							<div v-if="errors.length" class="invalid-tooltip">
								<span v-for="err in errors" :key="'form-payer-field-' + key + '_err'">{{err}}</span>
							</div>

						</template>
						<!---MASKED TEXT--->
						<template v-if="field.type === 'mask'">

							<label :for="'form-payer-field-' + key"
								   class="form-label"
								   v-if="field.label"
								   v-html="field.label"></label>

							<masked-input type="text"
										  class="form-control"
										  :class="errors.length ? 'is-invalid' : ''"
										  :placeholder="field.placeholder"
										  :name="'form-payer-field-' + key"
										  :id="'form-payer-field-' + key"
										  :maxlength="field.maxlength || 10000"
										  :pattern="field.mask"
										  @input="checkValidate(field.field_name || key)"
										  @change="checkValidate(field.field_name || key)"
										  v-model="form[field.field_name || key]">

							</masked-input>

							<div v-if="errors.length" class="invalid-tooltip">
								<span v-for="err in errors" :key="'form-payer-field-' + key + '_err'">{{err}}</span>
							</div>

						</template>
						<!---DADATA TYPE--->
						<template v-if="field.type === 'suggestion'">

							<label :for="'form-payer-field-' + key"
								   class="form-label"
								   v-if="field.label"
								   v-html="field.label"></label>

							<div class="position-relative" v-click-outside="clickOutsideSuggestions">
								<input type="text"
									   class="form-control"
									   :class="errors.length ? 'is-invalid' : ''"
									   :placeholder="field.placeholder"
									   :name="'form-payer-field-' + key"
									   :id="'form-payer-field-' + key"
									   :maxlength="field.maxlength || 10000"
									   v-filter="field.filter"
									   @focusin="field.showSuggestions = true"
									   @input="getData(field.field_name || key, key)"
									   @change="checkValidate(field.field_name || key)"
									   v-model="form[field.field_name || key]" />

								<div v-if="errors.length" class="invalid-tooltip">
									<span v-for="err in errors" :key="'form-payer-field-' + key + '_err'">{{err}}</span>
								</div>

								<vue-custom-scrollbar class="suggestions-list" v-if="field.suggestions && field.showSuggestions">
									<div class="suggestions-item"
										 v-for="(s_item, index) in field.suggestions"
										 :key="'s_' + index"
										 @click="selectSuggestionValue(key, s_item)">
										{{s_item.label}}
									</div>
								</vue-custom-scrollbar>
							</div>

						</template>
						<!---SEARCH TYPE--->
						<template v-if="field.type === 'search'">

							<label :for="'form-payer-field-' + key"
								   class="form-label"
								   v-if="field.label"
								   v-html="field.label"></label>
							<div class="input-group">
								<input type="text"
									   class="form-control"
									   :class="errors.length ? 'is-invalid' : ''"
									   :placeholder="field.placeholder"
									   :name="'form-payer-field-' + key"
									   :id="'form-payer-field-' + key"
									   :maxlength="field.maxlength || 10000"
									   @input="checkValidate(field.field_name || key)"
									   @change="checkValidate(field.field_name || key)"
									   v-model="form[field.field_name || key]" />
								<button type="button" class="btn btn-primary"><i class="fas fa-search"></i></button>
							</div>
							<div v-if="errors.length" class="invalid-tooltip">
								<span v-for="err in errors" :key="'form-payer-field-' + key + '_err'">{{err}}</span>
							</div>
						</template>
						<!---CHECKBOX--->
						<template v-else-if="field.type === 'checkbox'">
							<div :class="'form-check' + (errors.length ? ' is-invalid' : '')">
								<input type="checkbox"
									   @input="checkValidate(field.field_name || key)"
									   @change="checkValidate(field.field_name || key)"
									   v-model="form[field.field_name || key]"
									   :name="'form-payer-field-' + key"
									   :id="'form-payer-field-' + key"
									   class="form-check-input" />
								<label :for="'form-payer-field-' + key"
									   class="form-label"
									   v-if="field.label"
									   v-html="field.label"></label>
							</div>
							<div v-if="errors.length" class="invalid-tooltip">
								<span v-for="err in errors" :key="'form-payer-field-' + key + '_err'">{{err}}</span>
							</div>
						</template>
						<!---RADIO--->
						<template v-else-if="field.type === 'radio'">
							<div class="form-check mb-2" v-for="value in field.values" :key="value.value">
								<input type="radio"
									   @input="checkValidate(field.field_name || key)"
									   @change="checkValidate(field.field_name || key)"
									   :id="'form-payer-field-' + key + value.value"
									   :name="'form-payer-field-' + key"
									   :value="value.value"
									   v-model="form[field.field_name || key]"
									   class="form-check-input">
								<label :for="'form-payer-field-' + key + value.value"
									   class="form-label"
									   v-if="value.label"
									   v-html="value.label"></label>
							</div>
							<div v-if="errors.length" class="invalid-tooltip">
								<span v-for="err in errors" :key="'form-payer-field-' + key + '_err'">{{err}}</span>
							</div>
						</template>
					</template>
					<!---TEXTAREA--->
					<template v-else-if="field.tag === 'textarea'">

						<label :for="'form-payer-field-' + key"
							   class="form-label"
							   v-if="field.label"
							   v-html="field.label"></label>
						<div :class="field.readonly ? 'readonly' : ''">
									<textarea
											rows="3"
											class="form-control"
											:class="errors.length ? 'is-invalid' : ''"
											:placeholder="field.placeholder"
											:id="'form-payer-field-' + key"
											:name="'form-payer-field-' + key"
											:readonly="field.readonly"
											:maxlength="field.maxlength || 100000"
											v-filter="field.filter"
											@input="checkValidate(field.field_name || key)"
											@change="checkValidate(field.field_name || key)"
											v-model="form[field.field_name || key]"></textarea>
						</div>
						<div v-if="errors.length" class="invalid-tooltip">
							<span v-for="err in errors" :key="'form-payer-field-' + key + '_err'">{{err}}</span>
						</div>

					</template>
					<!---TEXT--->
					<template v-else-if="field.tag === 'div'">
						<div :class="field.class">{{field.label}}</div>
					</template>

				</ValidationProvider>
			</div>

		</ValidationObserver>
		<div class="d-flex justify-content-center w-100 mt-24px">
			<button type="button" @click="sendForm()" class="btn btn-primary btn-lg">
				Сохранить
			</button>
		</div>
	</div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import axios from 'axios'
  import appOptions from '@/config/options'

  export default {
    name: 'FizForm',
    props: ['button', 'data', 'idOrder', 'fieldsMap', 'fieldsForm', 'idBid'],
    data() {
      return {
        suggestionToken: appOptions.suggestionToken,
        typesFieldsMap: this.fieldsMap,
        form: {
          order_id: this.idOrder,
          ...this.fieldsForm,
        },
        options: {
          format: 'DD.MM.YYYY',
          useCurrent: false,
          locale: 'ru',
          maxDate: new Date(),
        },
      }
    },
    computed: {
      ...mapGetters({
        token: 'auth/token',
      }),
      formItems: function () {
        return JSON.parse(JSON.stringify(this.form))
      },
      user() {
        return this.$store.getters['user/user']
      },
    },
    watch: {
      formItems(newVal, oldVal) {
        this.typesFieldsMap.actual_address.readonly = false
        if (newVal.check) {
          this.form.actual_address = newVal.type_face === 'FIZ' ? newVal.registration_address : newVal.ur_address
          this.typesFieldsMap.actual_address.readonly = true
        }
        if (!newVal.check && oldVal.check) {
          this.form.actual_address = ''
        }
      },
      data(newVal, oldVal) {
        if (this.fieldsForm.type_face === newVal.type_face) {
          for (let key in newVal) {
            if (newVal.hasOwnProperty(key)) {
              this.form[key] = newVal[key]
              if (key === 'snils') {
                this.form[key] = newVal[key].replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/g, `$1-$2-$3-$4`)
              } else {
                this.form[key] = newVal[key]
              }
            }
          }
          if (newVal.registration_address && !newVal.ur_address) {
            this.form['ur_address'] = newVal.registration_address
          }
          if (!newVal.registration_address && newVal.ur_address) {
            this.form['registration_address'] = newVal.ur_address
          }
        }
      },
    },
    mounted() {
      if (this.data && this.fieldsForm.type_face === this.data.type_face) {
        for (let key in this.data) {
          if (this.data.hasOwnProperty(key)) {
            if (key === 'snils') {
              this.form[key] = this.data[key].replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/g, `$1-$2-$3-$4`)
            } else {
              this.form[key] = this.data[key]
            }
          }
        }
        if (this.data.registration_address && !this.data.ur_address) {
          this.form['ur_address'] = this.data.registration_address
        }
        if (!this.data.registration_address && this.data.ur_address) {
          this.form['registration_address'] = this.data.ur_address
        }
      }
    },
    destroyed() {
    },
    methods: {
      clickOutsideSuggestions: function (e) {
        for (let key in this.typesFieldsMap) {
          if (this.typesFieldsMap.hasOwnProperty(key) && this.typesFieldsMap[key].type === 'suggestion') {
            this.typesFieldsMap[key].showSuggestions = false
          }
        }
      },
      selectSuggestionValue: function (key, item) {
        if (this.typesFieldsMap[key]) {
          this.typesFieldsMap[key].showSuggestions = false
          const setFields = (fields, data) => {
            for (let key in data) {
              if (data.hasOwnProperty(key)) {
                this.form[key] = data[key]
              }
            }
          }
          setFields(this.typesFieldsMap[key], item)
        }
      },
      getData: function (key, keyMap) {
        if (!this.typesFieldsMap[keyMap]) return
        if (!this.form[key]) {
          this.typesFieldsMap[keyMap].suggestions = []
          this.typesFieldsMap[keyMap].showSuggestions = false
          return
        }
        axios({
          url: process.env.VUE_APP_API_URL + this.typesFieldsMap[keyMap].method,
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.token}`,
          },
          data: {
            q: this.form[key],
          },
        })
          .then(res => res.data)
          .then(res => {
            if (res.data) {
              this.typesFieldsMap[keyMap].suggestions = res.data ? res.data.map(item => {
                return {
                  ...item,
                  label: item.bik_bank || item.inn,
                }
              }) : []
              this.typesFieldsMap[keyMap].showSuggestions = !!res.data.length
            }
          })
      },
      checkValidate: function (name) {
        const provider = this.$refs[name]
        if (provider && provider.validate) {
          provider.validate()
        }
      },
      async sendForm() {
        const isValid = await this.$refs['payer_edit_observer' + this.fieldsForm.type_face].validate()
        if (isValid) {
          let params = this.removeEmptyValues(JSON.parse(JSON.stringify(this.form)))
          if (params.snils) {
            params.snils = params.snils.replace(/[^\d]/g, '')
          }
          let dispatchPath = 'orders/fetchUpdatePayer'
          if (this.idBid) {
            params.bid_id = this.idBid
            dispatchPath = 'bids/fetchUpdatePayer'
          }
          this.$store.dispatch(dispatchPath, params)
            .then((res) => {
              console.log('res', res)
              if (res.message && !res.success) {
                this.$notify({
                  group: 'center',
                  title: ``,
                  text: res.message,
                  type: 'error',
                })
              } else if (res.data) {
                this.$notify({
                  group: 'center',
                  title: ``,
                  text: 'Сохранено!',
                  type: 'success',
                })
                this.$bvModal.hide('modalPayerDetails')
              }
            })
        }
      },
      NumbersOnly(evt) {
        evt = (evt) ? evt : window.event
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault()

        } else {
          return true
        }
      },
      removeEmptyValues(object) {
        return typeof (!!object && object === 'object' && !Array.isArray(object))
          ? Object.fromEntries(Object.entries(object).filter(([_, value]) => value))
          : object
      },
    },
  }
</script>
