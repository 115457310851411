<template>
    <span>
		<a href="javascript:;" @click.prevent="showModal()" v-if="items && items.length">
			<slot name="button"></slot>
		</a>

		<b-modal :id="'AddListenerModalAddProgram'" size="md" centered>
			<template slot="modal-header">
				<h4 class="modal-title w-100 text-center">Выбрать слушателя</h4>
				<a href="javascript:void(0)" class="btn-close" @click="$bvModal.hide('AddListenerModalAddProgram')"></a>
			</template>

			<table class="table table-no-border-last align-middle mb-0">
              <tbody>

				<tr v-for="listener in items" :key="'listener_' + listener.id" v-if="listener.info">
                  <td>{{[listener.info.surname, listener.info.name, listener.info.patronymic].join(' ')}}</td>
                  <td class="text-nowrap">
					  {{$formatPhone(listener.info.phone)}}<br />
					  {{listener.info.email}}
				  </td>
                  <td class="w-100px">
                    <div class="form-check d-flex align-items-center justify-content-center">
                      <input type="checkbox"
							 v-model="listener.checked"
							 :id="'table_checkbox_' + listener.info.id"
							 class="form-check-input form-check-input-bordered">
                    </div>
                  </td>
                  <td class="w-150px pe-0 text-end">
                    <a href="javascript:void(0)" @click="addSelectedListener(listener)" class="btn btn-primary w-150px fs-12px px-5px">Выбрать</a>
                  </td>
                </tr>

              </tbody>
            </table>

			<div slot="modal-footer" class="d-flex justify-content-center w-100 gap-3">
				<button type="button" @click="addSelectedListeners" class="btn btn-primary btn-lg">
					Добавить выбранные
				</button>
			</div>
		</b-modal>
    </span>
</template>

<script>
  export default {
    name: 'AddListenerModalAddProgram',
    props: ['button', 'idOrder'],
    data() {
      return {
        items: [],
      }
    },
    computed: {
      listeners() {
        return this.idOrder ? this.$store.getters['listeners/itemsOrder'](this.idOrder) : []
      },
    },
    watch: {
      listeners(newVal, oldVal) {
        this.items = newVal.map(item => {
          return {
            ...item,
            checked: false,
          }
        })
      },
    },
    mounted() {
      this.items = this.listeners.map(item => {
        return {
          ...item,
          checked: false,
        }
      })
    },
    destroyed() {
    },
    methods: {
      showModal() {
        this.$bvModal.show('AddListenerModalAddProgram')
      },
	  addSelectedListener(listener) {
        this.$emit('addListeners', [listener])
        this.$bvModal.hide('AddListenerModalAddProgram')
	  },
      addSelectedListeners() {
        this.$emit('addListeners', this.items.filter(item => item.checked))
        this.$bvModal.hide('AddListenerModalAddProgram')
	  }
    },
  }
</script>
