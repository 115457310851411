export default {
  RESET_DOCUMENTS(state) {
    state = {
      itemsBids: [],
      items: [],
      types: [],
      pagination: {
        last_page: 1,
      }
    }
  },
  SET_ORDER_BID_DOCUMENTS_ITEMS(state, payload) {
    state.itemsBids = payload.items.map(item => {
      return {
        ...item,
        idOrder: Number(payload.idOrder),
        idBid: Number(payload.idBid)
      }
    })
  },
  SET_DOCUMENTS_ITEMS(state, payload) {
    state.items = payload.items
    state.pagination.last_page = payload.pagination ? payload.pagination.page_count : 1
  },
  SET_DOCUMENTS_TYPES(state, payload) {
    state.types = payload
  },
}
