export default {
  RESET_BID_ITEMS(state) {
    state = {
      items: [],
      items_confirmed: [],
      pagination: {
        last_page: 1,
      }
    }
  },
  SET_BID_ITEM(state, payload) {
    let tmp = []
    let find = false
    if (state.items.length) {
      state.items.forEach(item => {
        let itemTmp = item
        if (item.id.toString() === payload.id.toString()) {
          itemTmp = {
            ...item,
            ...payload,
          }
          find = true
        }
        tmp.push(itemTmp)
      })
    } else  {
      tmp.push(payload)
    }
    if (!find) {
      tmp.push(payload)
    }
    state.items = tmp
  },
  SET_CONFIRMED_BID_ITEM(state, payload) {
    let tmp = []
    let find = false
    if (state.items_confirmed.length) {
      state.items_confirmed.forEach(item => {
        let itemTmp = item
        if (item.id.toString() === payload.id.toString()) {
          itemTmp = {
            ...item,
            ...payload,
          }
          find = true
        }
        tmp.push(itemTmp)
      })
    } else  {
      tmp.push(payload)
    }
    if (!find) {
      tmp.push(payload)
    }
    state.items_confirmed = tmp
  },
  SET_PAYER_TO_BID(state, payload) {
    let tmp = []
    state.items.forEach(item => {
      let itemTmp = item
      if (item.id.toString() === payload.bid_id.toString()) {
        itemTmp = {
          ...item,
          payer_detail: payload.payer,
        }
      }
      tmp.push(itemTmp)
    })
    state.items = tmp
  },
  SET_BID_ITEMS(state, payload){
    if (payload.data) payload = payload.data
    state.items = payload
  },
  SET_PROGRAMS_ITEMS(state, payload){
    state.items_confirmed = payload.items
    state.pagination.last_page = payload.pagination ? payload.pagination.page_count : 1
  },
}
