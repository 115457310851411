import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'
import store from './store'

import './mixins'
import './plugins'
import './directives'

import App from './App.vue'

import { register } from 'register-service-worker'
import Echo from 'laravel-echo'

Vue.config.productionTip = false

window.Pusher = require('pusher-js')

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_APP_KEY,
  encrypted: true,
  cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
})

/*
 Vue.config.errorHandler = function(err, vm, info) {
 console.log(`Error: ${err.toString()}\nInfo: ${info}`);
 console.log(vm)
 }

 Vue.config.warnHandler = function(msg, vm, trace) {
 console.log(`Warn: ${msg}\nTrace: ${trace}`);
 }
 */

Vue.use(Vuex)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
