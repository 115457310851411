<template>
    <span>
		<a href="javascript:;" @click.prevent="showModal()">
			<slot name="button"></slot>
		</a>

		<b-modal :id="'DeleteListenerModal' + id" size="md" centered>
			<template slot="modal-header">
				<h4 class="modal-title w-100 text-center">Вы действительно хотите удалить {{listenerFio}} с программы “{{programName}}”?</h4>
				<a href="javascript:void(0)" class="btn-close" @click="$bvModal.hide('DeleteListenerModal')"></a>
			</template>

			<div class="text-center mt-n3 mb-3">Отменить это действие будет невозможно</div>

			<div slot="modal-footer" class="d-flex justify-content-between w-100 gap-3">
				<button type="button" class="btn btn-primary btn-lg flex-1" @click="send">
					Удалить
				</button>
				<button type="button" class="btn btn-gray btn-lg text-dark flex-1" @click="$bvModal.hide('DeleteListenerModal' + id)">
					Отменить
				</button>
			</div>
		</b-modal>
    </span>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'DeleteListenerModal',
    props: ['button', 'listenerFio', 'programName', 'id', 'idBid', 'idOrder', 'isBid'],
    data() {
      return {
      }
    },
    computed: {
      token() {
        return this.$store.getters['auth/token']
      },
      user() {
        return this.$store.getters['user/user']
      },
    },
    watch: {},
    mounted() {
    },
    destroyed() {
    },
    methods: {
      showModal() {
        this.$bvModal.show('DeleteListenerModal' + this.id)
      },
	  send() {
        let path = ''
        let roles = this.user.roles ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
        roles.forEach(role => {
          switch (role) {
            case 'contact_person':
              path = `/contact_person/orders/${this.idOrder}/bids/${this.idBid}/listeners/${this.id}/delete`
              break
            case 'sales_department_manager':
              path = `/mop/orders/${this.idOrder}/bids/${this.idBid}/listeners/${this.id}/delete`
              break
            case 'head_department_manager':
              path = `/rop/orders/${this.idOrder}/bids/${this.idBid}/listeners/${this.id}/delete`
              break
          }
        })
        axios({
          url: process.env.VUE_APP_API_URL + path,
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.token}`,
          },
        })
          .then(res => res.data)
          .then(res => {
            if (res) {
              if (this.isBid) {
                this.$store.dispatch('bids/fetchBidItem', { idOrder: this.idOrder, idBid: this.idBid })
              } else {
                this.$store.dispatch('orders/fetchOrderItem', this.idOrder)
              }
              this.$bvModal.hide('DeleteListenerModal' + this.id)
            }
          })
	  }
    },
  }
</script>
