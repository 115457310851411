<template>
	<svg :width="width" :height="height" :style="styleName" :class="className" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="40" height="40" rx="20" :fill="color2"/>
		<path d="M26.1123 12.6472C23.9161 10.4509 20.3396 10.4509 18.1457 12.6472L12.0606 18.7276C12.021 18.7672 12 18.8209 12 18.8768C12 18.9328 12.021 18.9864 12.0606 19.026L12.9209 19.8863C12.9603 19.9255 13.0135 19.9475 13.069 19.9475C13.1245 19.9475 13.1777 19.9255 13.217 19.8863L19.3021 13.8059C20.0575 13.0505 21.0624 12.6355 22.1302 12.6355C23.198 12.6355 24.2029 13.0505 24.9559 13.8059C25.7113 14.5613 26.1263 15.5662 26.1263 16.6316C26.1263 17.6994 25.7113 18.702 24.9559 19.4574L18.7542 25.6567L17.7494 26.6616C16.8098 27.6011 15.2827 27.6011 14.3431 26.6616C13.8885 26.2069 13.639 25.6031 13.639 24.9596C13.639 24.3161 13.8885 23.7123 14.3431 23.2576L20.4958 17.1072C20.6521 16.9534 20.8572 16.8671 21.0764 16.8671H21.0787C21.2979 16.8671 21.5007 16.9534 21.6546 17.1072C21.8108 17.2635 21.8947 17.4686 21.8947 17.6878C21.8947 17.9046 21.8085 18.1098 21.6546 18.2636L16.6256 23.2879C16.586 23.3276 16.565 23.3812 16.565 23.4371C16.565 23.4931 16.586 23.5467 16.6256 23.5864L17.4859 24.4467C17.5253 24.4858 17.5785 24.5078 17.634 24.5078C17.6895 24.5078 17.7427 24.4858 17.782 24.4467L22.8086 19.4201C23.2726 18.9561 23.5267 18.3406 23.5267 17.6854C23.5267 17.0303 23.2703 16.4125 22.8086 15.9508C21.8504 14.9926 20.293 14.9949 19.3348 15.9508L18.7379 16.55L13.1844 22.1012C12.8075 22.4759 12.5087 22.9218 12.3054 23.4128C12.1021 23.9039 11.9983 24.4304 12 24.9619C12 26.0414 12.422 27.0556 13.1844 27.818C13.9748 28.606 15.0099 29 16.0451 29C17.0803 29 18.1154 28.606 18.9035 27.818L26.1123 20.6138C27.1731 19.5506 27.7607 18.1354 27.7607 16.6316C27.763 15.1255 27.1755 13.7103 26.1123 12.6472Z" :fill="color"/>
	</svg>
</template>

<script>
  export default {
    name: 'IconFile',
	props: {
      'width': {
		type: Number,
		default: 40,
	  },
	  'height': {
        type: Number,
        default: 40,
	  },
	  'styleName': {
        type: String,
        default: ''
	  },
	  'color': {
        type: String,
        default: '#ffffff'
	  },
      'color2': {
        type: String,
        default: '#2BACEE'
      },
      'className': {
        type: String,
        default: ''
      },
    },
    data() {
      return {}
    },
  }
</script>
