<template>
    <span>
		<a href="javascript:;" @click.prevent="getData()">
			<slot name="button"></slot>
		</a>

		<b-modal :id="'DocumentHistoryModal' + idBid + '_' + typeDocument" size="md" centered>
			<template slot="modal-header">
				<h4 class="modal-title w-100 text-center">{{nameDocument}}</h4>
				<a href="javascript:void(0)" class="btn-close" @click="$bvModal.hide('DocumentHistoryModal' + idBid + '_' + typeDocument)"></a>
			</template>

			<template v-if="templateDocument && Object.keys(templateDocument).length">
				<div class="mb-8px">Шаблон документа</div>
				<div class="border border-radius px-12px py-14px d-flex align-items-center justify-content-between fs-12px mb-24px">
					<span class="col">
						<IconDocuments />
						<span class="ms-4px">{{templateDocument.file_name}}</span>
					</span>
					<div class="col text-end">
						<a class="text-decoration-underline" :href="templateDocument.file_link" :download="templateDocument.file_name">Скачать</a>
					</div>
				</div>
			</template>

			<template v-if="historyDocument && historyDocument.length">
				<div class="mb-8px">История изменений</div>
				<div :class="`${index === 0 ? '' : 'border-top '}px-12px py-14px d-flex align-items-center justify-content-between fs-12px`" v-for="(item, index) in historyDocument" :key="`history-item_${idBid}_${item.id}`">
					<span class="me-auto">
						<IconDocuments />
						<span class="ms-4px">{{item.document_file_name}}</span>
					</span>
					<div class="text-center">
						{{item.document_date}}
					</div>
					<div class="w-100px text-end">
						<a class="text-decoration-underline" :href="item.document_file_link" :download="item.document_file_name">Скачать</a>
					</div>
				</div>
			</template>

			<div slot="modal-footer" class="d-flex justify-content-center align-items-end w-100 gap-3 m-0">
				<div class="text-center">
					<a href="javascript:;"
					   @click="$bvModal.hide('DocumentHistoryModal' + idBid + '_' + typeDocument),$bvModal.show('RequestForChanges' + idBid + '_' + typeDocument)"
					   class="text-decoration-underline fw-600"
					   v-if="availableActions['can-add-bid_protocol_disagreement-document']">
						Сформировать протокол разногласий
					</a>
				</div>
				<button type="button" @click="$bvModal.hide('DocumentHistoryModal' + idBid + '_' + typeDocument)" class="btn btn-primary">
					Закрыть
				</button>
			</div>
		</b-modal>

		<b-modal :id="'RequestForChanges' + idBid + '_' + typeDocument" size="md" centered>
			<template slot="modal-header">
				<h4 class="modal-title w-100 text-center">{{nameDocument}}</h4>
				<a href="javascript:void(0)" class="btn-close" @click="$bvModal.hide('RequestForChanges' + idBid + '_' + typeDocument)"></a>
			</template>

			<InputField
					:type-field="`textarea`"
					:name-field="`Комментарий`"
					:filter-field="`[0-9а-яА-ЯёЁa-zA-Z \-]`"
					v-model="comment" />

			<div class="mb-3px text-dark mt-24px">Прикрепить файл</div>
			<InputFile @setFiles="setFiles" />

			<div slot="modal-footer" class="d-flex justify-content-center w-100 gap-3">
				<button type="button" @click="submitForm" class="btn btn-primary btn-lg">
					Отправить
				</button>
			</div>
		</b-modal>
    </span>
</template>

<script>
  import axios from 'axios'
  import IconDocuments from '@/components/icons/IconDocuments'
  import { mapGetters } from 'vuex'
  import InputFile from '@/components/elements/InputFile'
  import InputField from '@/components/elements/Input'

  export default {
    name: 'DocumentHistoryModal',
    components: { InputField, InputFile, IconDocuments },
    props: ['button', 'idBid', 'idOrder', 'typeDocument', 'availableActions'],
    data() {
      return {
        historyDocument: null,
        nameDocument: null,
        templateDocument: null,

        files: [],
        comment: '',
      }
    },
    computed: {
      ...mapGetters({
        token: 'auth/token',
      }),
      user() {
        return this.$store.getters['user/user']
      },
    },
    mounted() {
    },
    destroyed() {
    },
    methods: {
      showModal() {
        this.$bvModal.show('DocumentHistoryModal' + this.idBid + '_' + this.typeDocument)
      },

      setFiles(files) {
        this.files = files
      },

      getData() {
        let path = ''
        let roles = this.user.roles ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
        roles.forEach(role => {
          switch (role) {
            case 'contact_person':
              path = `/contact_person/orders/${this.idOrder}/bids/${this.idBid}/documents/history/get`
              break
            case 'sales_department_manager':
              path = `/mop/orders/${this.idOrder}/bids/${this.idBid}/documents/history/get`
              break
            case 'head_department_manager':
              path = `/rop/orders/${this.idOrder}/bids/${this.idBid}/documents/history/get`
              break
          }
        })
        axios({
          url: process.env.VUE_APP_API_URL + path,
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${this.token}`,
          },
          data: {
            document_type: this.typeDocument,
          },
        })
          .then(res => res.data)
          .then(res => {
            if (res.code === 401) {
              Promise.all([
                this.$store.dispatch('orders/reset'),
                this.$store.dispatch('notifications/reset'),
                this.$store.dispatch('documents/resetDocuments'),
                this.$store.dispatch('user/resetUser'),
                this.$store.dispatch('listeners/resetListeners'),
                this.$store.dispatch('bids/reset'),
                this.$store.dispatch('auth/logout')
              ]).then(() => {
                this.$router.push({ path: '/login' })
              })
            } else if (res.data?.document_type_code) {
              this.nameDocument = res.data.document_type_name
              this.historyDocument = res.data.history
              this.templateDocument = res.data.template
              this.showModal()
            } else {
              this.$notify({
                group: 'center',
                title: `Ошибка`,
                text: res.data.message,
                type: 'error',
              })
            }
          })
      },

      submitForm() {
        if (!this.files.length) {
          this.$notify({
            group: 'center',
            title: `Ошибка!`,
            text: 'Прикрепите файл',
            type: 'error',
          })
        }

        let formData = new FormData()

        this.files.forEach(file => {
          formData.append('document', file)
        })

        formData.append('document_type', 'bid_protocol_disagreement')
        formData.append('comment', this.comment)

        let path = ''
        let roles = this.user.roles ? this.user.role ? [this.user.role] : Array.isArray(this.user.roles) ? this.user.roles : [this.user.roles] : []
        roles.forEach(role => {
          switch (role) {
            case 'contact_person':
              path = `/contact_person/orders/${this.idOrder}/bids/${this.idBid}/documents/add`
              break
            case 'sales_department_manager':
              path = `/mop/orders/${this.idOrder}/bids/${this.idBid}/documents/add`
              break
            case 'head_department_manager':
              path = `/rop/orders/${this.idOrder}/bids/${this.idBid}/documents/add`
              break
          }
        })

        axios({
          url: process.env.VUE_APP_API_URL + path,
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.token}`,
          },
          data: formData,
        })
          .then(res => res.data)
          .then(res => {
            if (res.message) {
              this.$notify({
                group: 'center',
                title: `Ошибка!`,
                text: res.message,
                type: 'error',
              })
            } else {
              this.$notify({
                group: 'center',
                title: ``,
                text: `Документ успешно отправлен!`,
                type: 'error',
              })
              this.$bvModal.hide('RequestForChanges' + this.idBid + '_' + this.typeDocument)
              this.$store.dispatch('documents/fetchOrderBidDocumentsItems', { idOrder: this.idOrder, idBid: this.idBid })
            }
          })
      },
    },
  }
</script>
