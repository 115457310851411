import axios from 'axios'

export default {
  async fetchDocumentsItems({ rootState, commit }, params) {
    try {
      let path = ''
      if (rootState.user.user) {
        let roles = rootState.user.user ? rootState.user.user.role ? [rootState.user.user.role] : Array.isArray(rootState.user.user.roles) ? rootState.user.user.roles : [rootState.user.user.roles] : []
        roles.forEach(role => {
          switch (role) {
            case 'listener':
              path = `/listener/documents/list`
              break
            case 'contact_person':
              path = `/contact_person/documents/list`
              break
            case 'sales_department_manager':
              path = `/mop/documents/list`
              break
            case 'head_department_manager':
              path = `/rop/documents/list`
              break
          }
        })
      }
      const res = await axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
      })
      const result = await res.data ? commit('SET_DOCUMENTS_ITEMS', {
        items: res.data.data,
        idBid: params.idBid,
        idOrder: params.idOrder,
      }) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })
      return result
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err
    }
  },
  async fetchOrderBidDocumentsItems({ rootState, commit }, params) {
    try {
      let path = ''
      if (rootState.user.user) {
        let roles = rootState.user.user ? rootState.user.user.role ? [rootState.user.user.role] : Array.isArray(rootState.user.user.roles) ? rootState.user.user.roles : [rootState.user.user.roles] : []
        roles.forEach(role => {
          switch (role) {
            case 'contact_person':
              path = `/contact_person/orders/${params.idOrder}/bids/${params.idBid}/documents/list`
              break
            case 'sales_department_manager':
              path = `/mop/orders/${params.idOrder}/bids/${params.idBid}/documents/list`
              break
            case 'head_department_manager':
              path = `/rop/orders/${params.idOrder}/bids/${params.idBid}/documents/list`
              break
          }
        })
      }
      const res = await axios({
        url: process.env.VUE_APP_API_URL + path,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
      })
      const result = await res.data ? commit('SET_ORDER_BID_DOCUMENTS_ITEMS', {
        items: res.data.data,
        idBid: params.idBid,
        idOrder: params.idOrder,
      }) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })
      return result
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err
    }
  },
  async fetchDocumentsTypes({ rootState, commit }, params) {
    try {
      const res = await axios({
        url: process.env.VUE_APP_API_URL + `/dictionary/type_documents/bids/list`,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${rootState.auth.token}`,
        },
      })
      const result = await res.data ? commit('SET_DOCUMENTS_TYPES', res.data.data) : commit('toast/NEW', {
        type: 'error',
        message: res.error.message,
      }, { root: true })
      return result
    } catch (err) {
      commit('toast/NEW', { type: 'error', message: JSON.stringify(err) }, { root: true })
      return err
    }
  },
  resetDocuments({ rootState, commit }) {
    return commit('RESET_DOCUMENTS')
  }
}
