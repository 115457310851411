export default {
  itemById: state => id => {
    for (let key in state.items) {
      if (state.items.hasOwnProperty(key) && Number(state.items[key].id) === Number(id)) {
        return state.items[key]
      }
    }
    return null
  },
  itemConfirmedById: state => id => {
    for (let key in state.items_confirmed) {
      if (state.items_confirmed.hasOwnProperty(key) && Number(state.items_confirmed[key].id) === Number(id)) {
        return state.items_confirmed[key]
      }
    }
    return null
  },
  items: state => state.items,
  items_confirmed: state => state.items_confirmed,
  last_page: state => state.pagination.last_page,
}
